import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { scrollToForm } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-helper';

import './digital-checkout-activate-button.scss';

export function DigitalCheckoutActivateButton({ cta, classNames, icon }) {
  return (
    <Button
      data-tracking-id="caramel_activate_cta"
      className={classnames('activate-btn text-blue-10 text-transform-none size-20 px-2 py-0_75', classNames)}
      onClick={scrollToForm}
    >
      {cta} <i className={classnames('size-16', icon)} aria-hidden />
    </Button>
  );
}

DigitalCheckoutActivateButton.propTypes = {
  cta: PropTypes.string,
  classNames: PropTypes.string,
  icon: PropTypes.string,
};

DigitalCheckoutActivateButton.defaultProps = {
  cta: '',
  classNames: '',
  icon: 'icon-arrow-down8',
};
