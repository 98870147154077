import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TrackingHandler } from 'client/tracking/handler';
import { DealerCenterMap } from 'client/tracking/maps/microsite/dealers';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { renderEntries } from 'site-modules/microsite/dealers/utils/entries-renderer';
import {
  ICO_TARGET_CLASS,
  ICO_TEST_DEALER_ID,
  ICO_TEST_ZIP_CODE,
  ICO_UNIFIED_CONTAINER_SRC,
  ICO_WIDGET_DISPLAY_TYPES,
} from 'client/site-modules/shared/components/dealer-ico-widget/constants';
import { injectIcoScript } from 'client/site-modules/shared/components/dealer-ico-widget/ico-widget/ico-widget';

export const DealerCenterUI = ({ pageCategory, pageName, content, location }) => {
  useEffect(() => {
    TrackingHandler.useMap(DealerCenterMap);

    (async () => {
      await injectIcoScript(`${ICO_UNIFIED_CONTAINER_SRC}${ICO_TEST_DEALER_ID}.js`);
    })();
  }, []);

  useEffect(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
        subaction_name: TrackingConstant.SUBACTION_ATOM_FILE_NAME,
        value: `${pageCategory ? `${pageCategory}-` : ''}${pageName}`,
      },
    });
  }, [pageCategory, pageName]);

  return (
    <div className="dealer-center pos-r mb-3_5">
      {renderEntries({ entriesToRender: content.children(), pageName, location })}
      <div className="d-none">
        {/* Injection target for ICO widget */}
        <div
          className={ICO_TARGET_CLASS}
          data-zipcode={ICO_TEST_ZIP_CODE}
          data-display={ICO_WIDGET_DISPLAY_TYPES.NO_BANNER_OVERLAY}
        />
      </div>
    </div>
  );
};

DealerCenterUI.propTypes = {
  pageCategory: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  content: CmsEntities.Content,
  location: PropTypes.shape({}),
};

DealerCenterUI.defaultProps = {
  pageCategory: '',
  content: DEFAULT_CONTENT,
  location: {},
};
