import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';

import './dealer-center-button.scss';

export const DealerCenterButton = ({ ctaText, className, ...props }) => (
  <Button className={classnames('dealer-center-button size-16 pos-r', className)} {...props}>
    {ctaText}
  </Button>
);

DealerCenterButton.propTypes = {
  ctaText: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};

DealerCenterButton.defaultProps = {
  color: 'white',
  className: '',
};
