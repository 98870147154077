import { get } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { DIGITAL_CHECKOUT_EVENTS } from 'site-modules/shared/constants/financing/digital-checkout-events';

function fireCreateDealTracking({ detail: { deal, isPartner } }) {
  const dealId = get(deal, 'dealId');

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      subaction_name: TrackingConstant.CHECKOUT_DEEPLINK_COMPLETED,
      creative_id: `used-summary_checkout-${isPartner ? 'partner' : 'basic'}-${dealId ? 'success' : 'error'}`,
      value: dealId || `${get(deal, 'status')} - ${get(deal, 'message')}`,
    },
  });
}

function fireDealerStatusTracking({ detail: { dealerStatus, rooftopId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_SHOW_CONTENT,
      action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      subaction_name: TrackingConstant.DEALER_CATEGORY,
      creative_id: 'caramel-dealer-page-contents',
      value: dealerStatus,
    },
    ...(rooftopId && rooftopId !== 'undefined' && { partner_id: rooftopId }),
  });
}

function fireEnrollStatusTracking({ detail: { isSuccess = false, rooftopId, creativeId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      subaction_name: TrackingConstant.ENROLL_API_RESPONSE,
      creative_id: creativeId,
      value: isSuccess ? '201 Created' : '400 Bad Request',
    },
    ...(rooftopId && rooftopId !== 'undefined' && { partner_id: rooftopId }),
  });
}

function fireFormFieldTracking({ detail: { target, data, rooftopId, creativeId } }) {
  const { isValidated } = data;
  const fieldName = get(data, 'name', target.getAttribute('name'));
  const verifiedStatement = isValidated ? 'success' : 'failure';

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: `${fieldName}_input_${verifiedStatement}`,
      creative_id: creativeId,
    },
    ...(rooftopId && rooftopId !== 'undefined' && { partner_id: rooftopId }),
  });
}

function fireSubmitAttemptsTracking({ detail: { value, subactionName, creativeId, rooftopId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: subactionName,
      creative_id: creativeId,
      value,
    },
    ...(rooftopId && rooftopId !== 'undefined' && { partner_id: rooftopId }),
  });
}

function fireDetailsTracking({ detail: { creativeId, value } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_CONTENT,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.ACTION_VIEW_CONTENT,
      creative_id: creativeId,
      value,
    },
  });
}
function fireManageFormToggleTracking({ detail: { value, creativeId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_TOOL_SELECT,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.MANAGE_FORM_TOGGLE,
      creative_id: creativeId,
      value,
    },
  });
}

function fireSubmitSurveyTracking({ detail: { value, creativeId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      subaction_name: TrackingConstant.SUBMIT_SURVEY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      creative_id: creativeId,
      value,
    },
  });
}

function fireWidgetViewTracking({ detail: { creativeId } }) {
  const pageHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
    event_data: {
      action_name: TrackingConstant.ACTION_WIDGET_VIEW,
      action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
      creative_id: creativeId,
      total_page_height: pageHeight,
    },
  });
}

function fireSurveyApiResponseTracking({ detail: { isSuccess = false, creativeId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      subaction_name: TrackingConstant.SUBMIT_SURVEY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      creative_id: creativeId,
      value: isSuccess ? 'Success' : 'Failure',
    },
  });
}

export const DigitalCheckoutEngagementHandler = {
  init: () => {
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.CREATE_DEAL, fireCreateDealTracking); // TODO: should be removed when clean up TED-6468-Caramel-Checkout-V2
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.DEALER_STATUS, fireDealerStatusTracking);
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.ENROLL_STATUS, fireEnrollStatusTracking);
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.INPUT_VALIDATION, fireFormFieldTracking);
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.SUBMIT_ATTEMPTS, fireSubmitAttemptsTracking);
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.DETAILS, fireDetailsTracking);
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.MANAGE_FORM_TOGGLE, fireManageFormToggleTracking);
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.SUBMIT_SURVEY, fireSubmitSurveyTracking);
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.WIDGET_VIEW, fireWidgetViewTracking);
    EventToolbox.on(DIGITAL_CHECKOUT_EVENTS.SURVEY_API_RESPONSE, fireSurveyApiResponseTracking);
  },
};
