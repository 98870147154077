import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const DealerCenterMedia = ({ videoSrc, imageSrc, videoClass, imageClass, videoRef }) =>
  videoSrc ? (
    // We should use aria-hidden='true' as well as muted on the video element in
    // addition to the eslint-disable we will need in order to make a commit.
    // eslint-plugin-jsx-a11y v6.1.0 and up will fix the disable-eslint issue per the
    // slack thread here: https://edmunds.slack.com/archives/CAJFEMS23/p1683158323992569?thread_ts=1683154890.525059&cid=CAJFEMS23
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video autoPlay muted loop playsInline className={classnames(videoClass)} aria-hidden ref={videoRef}>
      <source src={videoSrc} type="video/mp4" />
    </video>
  ) : (
    <img src={imageSrc} aria-hidden alt="" className={classnames(imageClass)} />
  );

DealerCenterMedia.propTypes = {
  videoSrc: PropTypes.string,
  imageSrc: PropTypes.string,
  videoClass: PropTypes.string,
  imageClass: PropTypes.string,
  videoRef: PropTypes.shape({ current: PropTypes.object }),
};

DealerCenterMedia.defaultProps = {
  videoSrc: '',
  imageSrc: '',
  videoClass: '',
  imageClass: '',
  videoRef: undefined,
};
