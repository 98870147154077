import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { CommonMap } from 'client/tracking/maps/common';
import { MicrositeTrackingConstant } from './constants';

const map = {
  header_section_cta_click: TrackingEvent.actionProgress(TrackingConstant.ACTION_SHOW_CONTENT, TrackingConstant.SUBMIT),
  hero_module_submit_primary: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_SHOW_CONTENT,
    TrackingConstant.SUBMIT_PRIMARY
  ),
  hero_module_submit_secondary: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_SHOW_CONTENT,
    TrackingConstant.SUBMIT_SECONDARY
  ),
  submit: TrackingEvent.actionProgress(TrackingConstant.ACTION_SHOW_CONTENT, TrackingConstant.SUBMIT),
  play_pause: TrackingEvent.actionProgress(TrackingConstant.ACTION_SHOW_CONTENT, TrackingConstant.PLAY_PAUSE),
  nav_logo_click: TrackingEvent.actionCompleted(
    MicrositeTrackingConstant.ACTION_RESET_NAVIGATION,
    MicrositeTrackingConstant.RESET_NAVIGATION,
    {
      creative_id: MicrositeTrackingConstant.GLOBAL_NAV_CREATIVE_ID,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      value: 'Edmunds Logo',
    }
  ),
  nav_menu_item: TrackingEvent.actionCompleted(
    MicrositeTrackingConstant.ACTION_TOOL_SELECT,
    MicrositeTrackingConstant.TOOL_SELECT,
    {
      creative_id: MicrositeTrackingConstant.GLOBAL_NAV_CREATIVE_ID,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
    }
  ),

  nav_sign_in: TrackingEvent.actionCompleted(MicrositeTrackingConstant.ACTION_SIGN_IN, MicrositeTrackingConstant.LOGIN),
  nav_contact_us: TrackingEvent.actionCompleted(
    MicrositeTrackingConstant.ACTION_CONTACT_EDMUNDS,
    MicrositeTrackingConstant.CONTACT_US
  ),

  /* Digital Checkout */
  dealer_deactivation_consent: TrackingEvent.actionCompleted(
    MicrositeTrackingConstant.ACTION_FILL_FORM,
    MicrositeTrackingConstant.DEALER_DEACTIVATION_CONSENT
  ),
  dealer_enrollment_consent: TrackingEvent.actionCompleted(
    MicrositeTrackingConstant.ACTION_FILL_FORM,
    MicrositeTrackingConstant.DEALER_ENROLLMENT_CONSENT
  ),
  caramel_activate_cta: TrackingEvent.actionCompleted(
    MicrositeTrackingConstant.ACTION_VIEW_CONTENT,
    MicrositeTrackingConstant.PRIMERY_ACTION_CLICK
  ),
  learn_more_about_caramel: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    MicrositeTrackingConstant.CARAMEL_SITE_CLICKOUT,
    { action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK }
  ),
  enrollment_agreement_info: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    MicrositeTrackingConstant.ACTION_VIEW_CONTENT
  ),
};

export const DealerCenterMap = {
  ...CommonMap,
  ...map,
};
