import { scrollToElement } from 'client/utils/scroll';
import { SCROLL_TO_ENROLLMENT_FORM } from 'site-modules/shared/constants/financing/dealer-enrollment-page';
import { STATUS_ACTIVATED, STATUS_ELIGIBLE } from 'site-modules/shared/constants/financing/digital-checkout';

export const obscureEmail = email => {
  const [name, domain] = email.split('@');
  return `${name[0]}${new Array(name.length - 1).join('*')}${name[name.length - 1]}@${domain}`;
};

export const obscurePhone = phone => {
  const args = phone.match(/^(\d{3})(\d+)(\d{3})$/);
  return `(${args[1]})${args[2].replace(/./g, '*')}-${args[3].replace(/./g, '*')}`;
};

export const scrollToForm = () => {
  scrollToElement(SCROLL_TO_ENROLLMENT_FORM);
};

export const getCtaText = ({ dealerStatus, ctaText, activatedCtaText, invalidCtaText }) => {
  switch (dealerStatus) {
    case STATUS_ACTIVATED:
      return activatedCtaText;
    case STATUS_ELIGIBLE:
      return ctaText;
    default:
      return invalidCtaText;
  }
};

export const getEmailTokens = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const rooftopId = searchParams.get('rooftopId');

  return JSON.stringify({ rooftopId });
};
