import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormField } from 'site-modules/shared/components/form-field/form-field';
import { InputMaskWrapper } from 'site-modules/shared/components/input-mask/input-mask';

/**
 * @deprecated - Please use FormFieldMaskV2. This package is not being supported anymore.
 * This is base form field for venom.
 * It is a wrapper to input field from https://www.npmjs.com/package/react-input-mask
 * it can be used for masked inputs such as phone, email.
 */

export class FormFieldMask extends Component {
  static propTypes = {
    ...FormField.propTypes,
    // e.g. mask={ pattern: '(999) 999-9999', toValue: value => value.replace(/\D/g, '') }
    mask: PropTypes.shape({
      pattern: PropTypes.string.isRequired,
      toValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    }).isRequired,
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    ...FormField.defaultProps,
    defaultValue: '',
  };

  state = {
    value: this.props.defaultValue,
    applyMask: false, // this flag determines when mask should be applied, it needs for autofill
  };

  componentDidMount() {
    this.updateMaskState(this.state.value);
  }

  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;
    if (defaultValue !== prevProps.defaultValue) {
      this.updateMaskState(defaultValue);
    }
  }

  onChange = (event, name) => {
    const {
      target: { value },
    } = event;
    this.updateMaskState(value);
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(event, name, this.toValue(value));
    }
  };

  setValue = value => {
    this.updateMaskState(value);
    return value;
  };

  updateMaskState = value => {
    // check empty value
    const cleanValue = this.cleanMask(value);
    // mask should be applied when field is not empty
    const applyMask = !!cleanValue.length;
    // real value is empty (value contains mask), overwrite to empty value
    const currentValue = this.props.mask && !applyMask ? '' : value;
    this.setState({
      value: currentValue,
      applyMask,
    });
  };

  /**
   *  Mask string. Default format characters are:
   *  9: 0-9
   *  a: A-Z, a-z
   *  *: A-Z, a-z, 0-9
   */
  cleanMask = value => value.replace(/[^0-9a-zA-Z]/g, '');

  toValue = value => {
    const mask = this.props.mask;
    if (typeof mask.toValue === 'function') {
      return mask.toValue(value);
    } else if (mask.toValue) {
      return this.cleanMask(value);
    }
    return value;
  };

  value = () => this.toValue(this.state.value) || '';

  reset = () => {
    this.setState({
      value: '',
      applyMask: false,
    });
  };

  focus = () => {
    this.formField.current.focus();
  };

  formField = React.createRef();

  render() {
    const { onChange, value, defaultValue, tag, mask, ...props } = this.props;
    const maskPattern = this.state.applyMask ? mask.pattern : null;
    return (
      <FormField
        {...props}
        ref={this.formField}
        onChange={this.onChange}
        mask={maskPattern}
        tag={InputMaskWrapper}
        value={this.state.value}
      />
    );
  }
}
