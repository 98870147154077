import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import classNames from 'classnames';
/* Models */
import { CmsEntities } from 'client/data/models/cms';
/* Utils */
import { EventToolbox } from 'client/utils/event-toolbox';
import { getQuery } from 'client/utils/location';
import { getSurveyParams } from 'site-modules/microsite/dealers/utils/delaer-center-enroll-form-utils';
/* Constants */
import { TrackingConstant } from 'client/tracking/constant';
import {
  DEALER_REQUEST_FORM,
  INVALID_MODE,
  WUFOO_SURVEY_URL,
} from 'site-modules/microsite/dealers/constants/digital-checkout';
import { DIGITAL_CHECKOUT_EVENTS } from 'site-modules/shared/constants/financing/digital-checkout-events';
import { ClientConfig } from 'client/configuration';
/* Components */
import { SuccessMessage } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/success-message/success-message';
import { ActivationFormWrapper } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/activation-form-wrapper/activation-form-wrapper';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

export const ActivationFormInvalid = ({ entry, dealer, location }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const metadata = entry.getAllMetadata();

  if (isEmpty(metadata)) return null;

  const { thankYouHeader, thankYouText, formHeader, learnMoreCaramelLink } = metadata;

  const submitCallback = async fields => {
    const rooftopId = get(getQuery(location), 'rooftopId');
    EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.SUBMIT_ATTEMPTS, {
      value: 'Submit Request',
      subactionName: TrackingConstant.SUBMIT_REQUEST,
      creativeId: DEALER_REQUEST_FORM,
      rooftopId,
    });

    fetch(WUFOO_SURVEY_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${ClientConfig.get('carBuying').caramelWufooAuth}`,
      },
      body: new URLSearchParams(getSurveyParams({ fields, dealer })),
    })
      .then(response => response.json())
      .then(data => {
        setShowSuccess(true);
        EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.SURVEY_API_RESPONSE, {
          isSuccess: Number(get(data, 'Success')) === 1,
          creativeId: DEALER_REQUEST_FORM,
        });
      })
      .catch(() => {
        setShowSuccess(true);
        EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.SURVEY_API_RESPONSE, {
          creativeId: DEALER_REQUEST_FORM,
        });
      });
  };

  return (
    <div data-tracking-parent={DEALER_REQUEST_FORM}>
      <div
        className={classNames('text-white text-center digital-checkout-activation-form invalid mx-auto', {
          'p-1 p-md-3 d-flex align-items-center': showSuccess,
          'p-1_25': !showSuccess,
        })}
      >
        {showSuccess ? (
          <SuccessMessage thankYouHeader={thankYouHeader} thankYouText={thankYouText} hideCarLogo />
        ) : (
          <>
            <h2 className="size-24 text-cool-gray-10 mb-1_5">{formHeader}</h2>
            <ActivationFormWrapper
              dealer={dealer}
              location={location}
              dataTrackingParent={DEALER_REQUEST_FORM}
              submitCallback={submitCallback}
              metadata={metadata}
              mode={INVALID_MODE}
            />
          </>
        )}
      </div>
      <ContentFragment classes="text-center size-20 text-white mt-1_5">{learnMoreCaramelLink}</ContentFragment>
    </div>
  );
};

ActivationFormInvalid.propTypes = {
  entry: CmsEntities.Content.isRequired,
  dealer: PropTypes.shape({}),
  location: PropTypes.shape({}),
};

ActivationFormInvalid.defaultProps = {
  dealer: {},
  location: {},
};
