import { useEffect } from 'react';
import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';

export const useScrollIntoViewAnimation = ({ animationClassNames, ref }) => {
  const [isElementVisible] = useOnScreen(ref);

  useEffect(() => {
    if (isElementVisible) {
      ref.current.classList.add(...animationClassNames);
    }
  }, [isElementVisible, ref, animationClassNames]);
};
