import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { CmsEntities } from 'client/data/models/cms';
import { getCtaText } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-helper';
import { DigitalCheckoutActivateButton } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activate-button/digital-checkout-activate-button';

import './digital-checkout-hero.scss';

export const DigitalCheckoutHeroUI = ({ entry, isMobile, dealerStatus }) => {
  const metadata = entry.getAllMetadata();

  if (isEmpty(metadata)) return null;

  const {
    heroBackgroundDesktop,
    heroBackgroundMobile,
    heroImageDesktop,
    heroImageMobile,
    logo,
    header,
    ctaText,
    activatedCtaText,
    invalidCtaText,
  } = metadata;

  const bullets = entry.children();

  return (
    <div
      className="digital-checkout-hero"
      style={{
        backgroundImage: `url(${isMobile ? heroBackgroundMobile : heroBackgroundDesktop})`,
        backgroundSize: 'cover',
      }}
    >
      <Container className="pl-2_75 pr-0">
        <Row className="hero-content-wrapper">
          <Col xs={12} md={6} className="mt-3_5 mt-md-2 hero-content" data-tracking-parent="caramel-header">
            <img src={logo} alt="" aria-hidden />
            <h1 className="text-white mt-1_5 mb-1 size-36">{header}</h1>
            <ul
              className={classnames('text-white list-unstyled p-0 mb-0_5', {
                'size-24': !isMobile,
                'size-16': isMobile,
              })}
            >
              {bullets.map((bullet, index) => {
                const { copy } = bullet.getAllMetadata();
                return (
                  <li className="pb-1 d-flex" key={`hero-item_${index + 1}`}>
                    <i className="text-white icon-arrow-right5 px-0_5 mr-0_5" aria-hidden />
                    <div>{copy}</div>
                  </li>
                );
              })}
            </ul>
            <DigitalCheckoutActivateButton
              cta={getCtaText({ dealerStatus, ctaText, activatedCtaText, invalidCtaText })}
              classNames="px-2 py-0_75 mx-auto mx-md-0 d-block"
            />
            {isMobile && <img src={heroImageMobile} className="hero-image pos-a" aria-hidden alt="" />}
          </Col>
          {!isMobile && (
            <Col xs={12} md={6} className="pt-1_75">
              <img src={heroImageDesktop} aria-hidden alt="" />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

DigitalCheckoutHeroUI.propTypes = {
  entry: CmsEntities.Content.isRequired,
  isMobile: PropTypes.bool,
  dealerStatus: PropTypes.string,
};

DigitalCheckoutHeroUI.defaultProps = {
  isMobile: false,
  dealerStatus: '',
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const DigitalCheckoutHero = connect(mapStateToProps)(DigitalCheckoutHeroUI);
