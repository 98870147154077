import React from 'react';

export const BENEFITS_BACKGROUND_MOBILE = (
  <svg style={{ height: '100%', width: '100%' }} viewBox="0 0 375 781" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-794 780.732C-177 739.182 406 780.732 406 780.732V24.3042C-36.5 -7.12488 -299.242 -7.12488 -794 24.3042V780.732Z"
      fill="url(#paint0_linear_4531_164086)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4531_164086"
        x1={-75.4998}
        y1={390.732}
        x2={314.193}
        y2={-812.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '#00DFFF', stopOpacity: 0 }} />
        <stop offset="100%" style={{ stopColor: '#FFFFFF', stopOpacity: 0.5 }} />
      </linearGradient>
    </defs>
  </svg>
);

export const BENEFITS_BACKGROUND = (
  <svg
    preserveAspectRatio="none"
    style={{ height: '100%', width: '100%' }}
    aria-hidden
    viewBox="0 0 1200 781"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 780.621C617 739.071 1200 780.621 1200 780.621V24.1929C757.5 -7.23621 494.758 -7.23621 0 24.1929V780.621Z"
      fill="url(#paint0_linear_4531_166825)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4531_166825"
        x1={718.5}
        y1={390.621}
        x2={1108.19}
        y2={-812.911}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '##00DFFF', stopOpacity: 0 }} />
        <stop offset="100%" style={{ stopColor: '#FFFFFF', stopOpacity: 0.5 }} />
      </linearGradient>
    </defs>
  </svg>
);

export const FEES_AND_BONUSES_BACKGROUND_MOBILE = (
  <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 375 806" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-148.5 780.3C672.624 738.75 1448.5 780.3 1448.5 780.3V23.8716C859.606 -7.5575 509.941 -7.5575 -148.5 23.8716V780.3Z"
      fill="url(#paint0_linear_4531_164360)"
    />
    <path
      d="M-198.5 25.2998C622.624 66.8501 1398.5 25.2998 1398.5 25.2998V781.728C809.606 813.157 459.941 813.157 -198.5 781.728V25.2998Z"
      fill="url(#paint1_linear_4531_164360)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4531_164360"
        x1={807.704}
        y1={390.3}
        x2={1113.14}
        y2={-865.099}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '#00DFFF', stopOpacity: '0' }} />
        <stop offset="100%" style={{ stopColor: '#FFFFFF', stopOpacity: '0.5' }} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4531_164360"
        x1={-198.5}
        y1={-291.2}
        x2={242.763}
        y2={574.046}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '#FFFFFF', stopOpacity: '0.5' }} />
        <stop offset="100%" style={{ stopColor: '#00DFFF', stopOpacity: '0' }} />
      </linearGradient>
    </defs>
  </svg>
);
export const FEES_AND_BONUSES_BACKGROUND = (
  <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 1200 806" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-148.5 780.621C672.624 739.071 1448.5 780.621 1448.5 780.621V24.1929C859.606 -7.23621 509.941 -7.23621 -148.5 24.1929V780.621Z"
      fill="url(#paint0_linear_4531_167046)"
    />
    <path
      d="M-198.5 25.6211C622.624 67.1714 1398.5 25.6211 1398.5 25.6211V782.049C809.606 813.478 459.941 813.478 -198.5 782.049V25.6211Z"
      fill="url(#paint1_linear_4531_167046)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4531_167046"
        x1={807.704}
        y1={390.621}
        x2={1113.14}
        y2={-864.778}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '#00DFFF', stopOpacity: '0' }} />
        <stop offset="100%" style={{ stopColor: '#FFFFFF', stopOpacity: '0.5' }} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4531_167046"
        x1={-198.5}
        y1={-290.879}
        x2={242.763}
        y2={574.368}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '#FFFFFF', stopOpacity: '0.5' }} />
        <stop offset="100%" style={{ stopColor: '#00DFFF', stopOpacity: '0' }} />
      </linearGradient>
    </defs>
  </svg>
);

export const DEALER_ENROLL_FORM_BACKGROUND_MOBILE = (
  <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 375 858" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 801.3C617 759.75 1200 801.3 1200 801.3V44.8716C757.5 13.4425 494.758 13.4425 0 44.8716V801.3Z"
      fill="url(#paint0_linear_4531_164241)"
    />
    <path
      d="M0 77.376C617 118.926 1200 77.376 1200 77.376V833.804C757.5 865.233 494.758 865.233 0 833.804V77.376Z"
      fill="url(#paint1_linear_4531_164241)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4531_164241"
        x1={718.5}
        y1={411.3}
        x2={1108.19}
        y2={-792.233}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '#00DFFF', stopOpacity: '0' }} />
        <stop offset="100%" style={{ stopColor: '#FFFFFF', stopOpacity: '0.5' }} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4531_164241"
        x1={-7.3446e-5}
        y1={-239.124}
        x2={506.614}
        y2={507.318}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '#FFFFFF', stopOpacity: '0.5' }} />
        <stop offset="100%" style={{ stopColor: '#00DFFF', stopOpacity: '0' }} />
      </linearGradient>
    </defs>
  </svg>
);

export const DEALER_ENROLL_FORM_BACKGROUND = (
  <svg style={{ width: '100%', height: '100%' }} viewBox="0 0 1200 858" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 801.621C617 760.071 1200 801.621 1200 801.621V45.1929C757.5 13.7638 494.758 13.7638 0 45.1929V801.621Z"
      fill="url(#paint0_linear_4531_166927)"
    />
    <path
      d="M0 77.6973C617 119.248 1200 77.6973 1200 77.6973V834.125C757.5 865.555 494.758 865.555 0 834.125V77.6973Z"
      fill="url(#paint1_linear_4531_166927)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4531_166927"
        x1={718.5}
        y1={411.621}
        x2={1108.19}
        y2={-791.911}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '#00DFFF', stopOpacity: '0' }} />
        <stop offset="100%" style={{ stopColor: '#FFFFFF', stopOpacity: '0.5' }} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4531_166927"
        x1={-7.3446e-5}
        y1={-238.803}
        x2={506.614}
        y2={507.64}
        gradientUnits="userSpaceOnUse"
      >
        <stop style={{ stopColor: '#FFFFFF', stopOpacity: '0.5' }} />
        <stop offset="100%" style={{ stopColor: '#00DFFF', stopOpacity: '0' }} />
      </linearGradient>
    </defs>
  </svg>
);
