import { useEffect } from 'react';
import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';
import { EventToolbox } from 'client/utils/event-toolbox';

/* MAKE SURE YOU ACTUALLY NEED THIS HOOK!!!
 * This hook is to be used when an additional pixel needs to be fired when an element comes into view, especially when the pixel requires a value.
 * In all other cases when you just need to fire a pixel when the element is visible, use Venom's built-in widget_view pixel.
 *
 *         /\
 *        /  \
 *      /     \
 *     /       \
 *    /         \
 *   /___     ___\
 *      |    |
 *      |    |
 *      |    |
 *      |____|
 *
 *     READ THIS!!!
 */
export const useScrollIntoViewTracking = ({ eventData, additionalConditions = true, ref }) => {
  const [isComponentVisible] = useOnScreen(ref);

  useEffect(() => {
    if (isComponentVisible && additionalConditions) {
      EventToolbox.fireTrackAction(eventData);
    }
  }, [eventData, isComponentVisible, additionalConditions]);
};
