import React from 'react';
import { DealerCenterLeadForm } from 'site-modules/microsite/dealers/components/dealer-center-lead-form/dealer-center-lead-form';
import { DealerCenterSectionHeader } from 'site-modules/microsite/dealers/components/dealer-center-section-header/dealer-center-section-header';
import { DealerCenterHeroModule } from 'site-modules/microsite/dealers/components/dealer-center-hero-module/dealer-center-hero-module';
import { DealerCenterStatistics } from 'site-modules/microsite/dealers/components/dealer-center-statistics/dealer-center-statistics';
import { DealerCenterVideoModule } from 'site-modules/microsite/dealers/components/dealer-center-video-module/dealer-center-video-module';
import { DealerCenterSideBySideModule } from 'site-modules/microsite/dealers/components/dealer-center-side-by-side-module/dealer-center-side-by-side-module';
import { DealerCenterRichTextModule } from 'site-modules/microsite/dealers/components/dealer-center-rich-text-module/dealer-center-rich-text-module';
import { DealerCenterFaq } from 'site-modules/microsite/dealers/components/dealer-center-faq/dealer-center-faq';
import { DealerCenterFinePrint } from 'site-modules/microsite/dealers/components/dealer-center-fine-print/dealer-center-fine-print';
import { DealerCenterFeatureGrid } from 'site-modules/microsite/dealers/components/dealer-center-feature-grid/dealer-center-feature-grid';
import { DealerCenterPartnershipsModule } from 'site-modules/microsite/dealers/components/dealer-center-partherships-module/dealer-center-partherships-module';
import { DealerCenterVideoBackground } from 'site-modules/microsite/dealers/components/dealer-center-video-background/dealer-center-video-background';
import { DealerCenterEdmundsVsOthersModule } from 'site-modules/microsite/dealers/components/dealer-center-edmunds-vs-others-module/dealer-center-edmunds-vs-others-module';
import { DealerCenterTestimonialModule } from 'site-modules/microsite/dealers/components/dealer-center-testimonial-module/dealer-center-testimonial-module';
import { DealerCenterDigitalCheckout } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/dealer-center-digital-checkout';

const entriesTypes = {
  faq: /faq/i,
  featureGrid: /feature-grid/i,
  finePrint: /fine-print/i,
  hero: /hero(?!-digital-checkout)/i,
  leadForm: /lead-form/i,
  partnership: /partnership/i,
  richText: /rich-text/i,
  sectionHeader: /section-header/i,
  sideBySide: /side-by-side/i,
  statistics: /statistics/i,
  testimonial: /testimonial/i,
  versusOthers: /versus-others/i,
  video: /video(?!-background)/,
  videoBackground: /video-background/i,
  digitalCheckout: /digital-checkout/i,
};

export const renderEntries = ({ entriesToRender, pageName, location }) =>
  entriesToRender.reduce((entries, entry, index) => {
    const key = `${entry.id}-${index.toString()}`;
    switch (true) {
      case entriesTypes.faq.test(entry.id): {
        entries.push(<DealerCenterFaq key={key} entry={entry} />);
        break;
      }
      case entriesTypes.featureGrid.test(entry.id): {
        entries.push(<DealerCenterFeatureGrid key={key} entry={entry} />);
        break;
      }
      case entriesTypes.finePrint.test(entry.id): {
        entries.push(<DealerCenterFinePrint key={key} entry={entry} />);
        break;
      }
      case entriesTypes.hero.test(entry.id): {
        entries.push(<DealerCenterHeroModule key={key} entry={entry} />);
        break;
      }
      case entriesTypes.leadForm.test(entry.id): {
        entries.push(<DealerCenterLeadForm key={key} entry={entry} pageName={pageName} />);
        break;
      }
      case entriesTypes.partnership.test(entry.id): {
        entries.push(<DealerCenterPartnershipsModule key={key} entry={entry} />);
        break;
      }
      case entriesTypes.richText.test(entry.id): {
        entries.push(<DealerCenterRichTextModule key={key} entry={entry} />);
        break;
      }
      case entriesTypes.sectionHeader.test(entry.id): {
        entries.push(<DealerCenterSectionHeader key={key} entry={entry} />);
        break;
      }
      case entriesTypes.sideBySide.test(entry.id): {
        entries.push(<DealerCenterSideBySideModule key={entry.id} entry={entry} />);
        break;
      }
      case entriesTypes.statistics.test(entry.id): {
        entries.push(<DealerCenterStatistics key={key} entry={entry} />);
        break;
      }
      case entriesTypes.testimonial.test(entry.id): {
        entries.push(<DealerCenterTestimonialModule key={key} entry={entry} />);
        break;
      }
      case entriesTypes.versusOthers.test(entry.id): {
        entries.push(<DealerCenterEdmundsVsOthersModule key={key} entry={entry} />);
        break;
      }
      case entriesTypes.videoBackground.test(entry.id): {
        entries.push(<DealerCenterVideoBackground key={key} entry={entry} />);
        break;
      }
      case entriesTypes.video.test(entry.id): {
        entries.push(<DealerCenterVideoModule key={key} entry={entry} />);
        break;
      }
      case entriesTypes.digitalCheckout.test(entry.id): {
        entries.push(<DealerCenterDigitalCheckout key={key} entry={entry} pageName={pageName} location={location} />);
        break;
      }
      default:
        break;
    }
    return entries;
  }, []);
