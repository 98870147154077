import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FadeInSlideUpOnScroll } from 'site-modules/shared/components/animated/fade-in-slide-up';
import { DealerCenterCarLogo } from 'site-modules/microsite/dealers/components/dealer-center-car-logo/dealer-center-car-logo';

export const SuccessMessage = ({ thankYouHeader, thankYouText }) => (
  <Fragment>
    <FadeInSlideUpOnScroll>
      <h2 className="title heading-2 text-white mb-1">{thankYouHeader}</h2>
    </FadeInSlideUpOnScroll>
    <FadeInSlideUpOnScroll>
      <p className="sub-title text-white mb-0 mx-auto">{thankYouText}</p>
    </FadeInSlideUpOnScroll>
    <FadeInSlideUpOnScroll>
      <DealerCenterCarLogo />
    </FadeInSlideUpOnScroll>
  </Fragment>
);

SuccessMessage.propTypes = {
  thankYouHeader: PropTypes.string.isRequired,
  thankYouText: PropTypes.string.isRequired,
};
