import React from 'react';

export const ARROW_WHITE = (
  <figure className="pos-a arrow">
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '31', height: '35' }} viewBox="0 0 31 35" fill="none">
      <path d="M12.7 34.9H0L0 0L12.7 0L30.1 17.5L12.7 34.9Z" fill="#FFFFFF" />
    </svg>
  </figure>
);

export const ARROW = (
  <figure className="pos-a arrow">
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '31', height: '35' }} viewBox="0 0 31 35" fill="none">
      <path d="M12.7 34.9H0L0 0L12.7 0L30.1 17.5L12.7 34.9Z" fill="url(#paint0_linear_4685_197131)" />
      <defs>
        <linearGradient
          id="paint0_linear_4685_197131"
          x1={0}
          y1={0}
          x2={-0.00511855}
          y2={22.9943}
          gradientUnits="userSpaceOnUse"
        >
          <stop style={{ stopColor: '#D8EEEA' }} />
          <stop offset="100%" style={{ stopColor: '#DDF0EA' }} />
        </linearGradient>
      </defs>
    </svg>
  </figure>
);

export const ARROW_MOBILE = (
  <figure className="pos-a arrow">
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '35', height: '31' }} viewBox="0 0 35 31" fill="none">
      <path
        d="M0.000396729 12.7V0L34.9004 0V12.7L17.4004 30.1L0.000396729 12.7Z"
        fill="url(#paint0_linear_4531_167174)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4531_167174"
          x1={4}
          y1={-1.34929e-6}
          x2={35}
          y2={19.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop style={{ stopColor: '#DBF2EE' }} />
          <stop offset="100%" style={{ stopColor: '#E0F1EC' }} />
        </linearGradient>
      </defs>
    </svg>
  </figure>
);

export const ARROW_WHITE_MOBILE = (
  <figure className="pos-a arrow">
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '35', height: '31' }} viewBox="0 0 35 31" fill="none">
      <path d="M0.000396729 12.7V0L34.9004 0V12.7L17.4004 30.1L0.000396729 12.7Z" fill="white" />
    </svg>
  </figure>
);
