import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './dealer-center-car-logo.scss';

export const DealerCenterCarLogo = ({ iconContainerClassName }) => (
  <div className="dealer-center-car-logo pos-r">
    <div className={classnames('icon-edmunds-car-container pos-a', iconContainerClassName)}>
      <i className="icon-edmunds-car secondary-icon shadow-icon" aria-hidden />
      <div className="icon-background-gradient">
        <i className="icon-edmunds-car text-primary" aria-hidden />
      </div>
      <i className="icon-edmunds-car secondary-icon gradient-icon text-primary" aria-hidden />
    </div>
  </div>
);

DealerCenterCarLogo.propTypes = {
  iconContainerClassName: PropTypes.string,
};

DealerCenterCarLogo.defaultProps = {
  iconContainerClassName: 'p-1_25',
};
