import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';

import './play-pause-button.scss';

export const PlayPauseButton = ({ isPlaying, onClick, size, trackingId, trackingValue, iconClassName }) => (
  <Button
    onClick={onClick}
    aria-hidden
    className="play-pause-button bg-transparent d-flex align-items-center justify-content-center p-0 pos-a rounded-circle"
    data-tracking-id={trackingId}
    data-tracking-value={trackingValue}
    style={{ height: size, width: size }}
  >
    <span className={classnames(iconClassName, isPlaying ? 'icon-pause21' : 'icon-play41')} />
  </Button>
);

PlayPauseButton.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number,
  trackingId: PropTypes.string,
  trackingValue: PropTypes.string,
  iconClassName: PropTypes.string,
};

PlayPauseButton.defaultProps = {
  size: 60,
  trackingId: undefined,
  trackingValue: undefined,
  iconClassName: '',
};
