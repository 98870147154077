import { get } from 'lodash';
import {
  DEAL_COMPLETED,
  DEAL_IN_PROGRESS,
  DEAL_INACTIVE,
  DEAL_NOT_STARTED,
  DEAL_STATUS_PENDING,
  DEAL_STATUS_SOLD,
} from 'site-modules/shared/constants/financing/caramel';

export const isCaramelEligible = vinData => !!get(vinData, 'prices.displayPrice');

export const getDealStatus = buyerStatus => {
  switch (buyerStatus) {
    case DEAL_IN_PROGRESS:
    case DEAL_NOT_STARTED:
      return DEAL_STATUS_PENDING;

    case DEAL_COMPLETED:
    case DEAL_INACTIVE:
      return DEAL_STATUS_SOLD;
    default:
      return null;
  }
};
