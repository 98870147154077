import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

export function AnimatedOnConditionElement({ shouldAnimate, tag, className, children, animationClassNames }) {
  const Element = tag;
  const ref = useRef(null);

  useEffect(() => {
    if (shouldAnimate) {
      ref.current.classList.add(...animationClassNames);
    }
  }, [animationClassNames, shouldAnimate]);

  return (
    <Element className={className} ref={ref}>
      {children}
    </Element>
  );
}

AnimatedOnConditionElement.propTypes = {
  shouldAnimate: PropTypes.bool,
  tag: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  animationClassNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AnimatedOnConditionElement.defaultProps = {
  shouldAnimate: false,
  tag: 'div',
  className: '',
  children: null,
};
