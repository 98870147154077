import React, { useRef } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { SECTION_HEADER_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { TrackingConstant } from 'client/tracking/constant';
import { DealerCenterButton } from 'site-modules/microsite/dealers/components/dealer-center-button/dealer-center-button';
import { FadeInSlideUpOnScroll } from 'site-modules/shared/components/animated/fade-in-slide-up';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';

import './dealer-center-section-header.scss';

export const DealerCenterSectionHeader = ({ entry }) => {
  const containerRef = useRef(null);
  const metadata = entry.getAllMetadata();
  const isEmptyMetadata = isEmpty(metadata);

  const { headerText, subheaderText, ctaText, ctaLink, ctaColor, ctaDisplay, ctaClass, ctaNewTab } = metadata;

  const eventDataWhenScrolledIntoView = {
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
      action_name: TrackingConstant.ACTION_SHOW_CONTENT,
      subaction_name: TrackingConstant.SUBACTION_SECTION_HEADER_MODULE_DISPLAYED,
      creative_id: SECTION_HEADER_CREATIVE_ID,
      value: headerText,
    },
  };

  useScrollIntoViewTracking({
    eventData: eventDataWhenScrolledIntoView,
    additionalConditions: !isEmptyMetadata,
    ref: containerRef,
  });

  if (isEmptyMetadata) return null;

  return (
    <div
      className="section-header-container text-center my-3 px-1_75"
      data-tracking-parent={SECTION_HEADER_CREATIVE_ID}
      ref={containerRef}
    >
      {headerText && (
        <FadeInSlideUpOnScroll>
          <ContentFragment componentToUse={'div'} classes="section-header text-white font-weight-bold size-36 mb-1_25">
            {headerText}
          </ContentFragment>
        </FadeInSlideUpOnScroll>
      )}
      {subheaderText && (
        <FadeInSlideUpOnScroll>
          <ContentFragment
            componentToUse={'div'}
            classes="section-subheader text-white font-weight-medium size-24 mx-auto"
          >
            {subheaderText}
          </ContentFragment>
        </FadeInSlideUpOnScroll>
      )}
      {ctaDisplay === 'true' && ctaText && (
        <FadeInSlideUpOnScroll>
          <DealerCenterButton
            color={ctaColor}
            ctaText={ctaText}
            data-tracking-id="header_section_cta_click"
            data-tracking-value={ctaText}
            className={classNames('font-weight-bold text-uppercase mt-2_5 w-100', ctaClass)}
            href={ctaLink}
            target={ctaNewTab === 'true' ? '_blank' : '_self'}
          />
        </FadeInSlideUpOnScroll>
      )}
    </div>
  );
};

DealerCenterSectionHeader.propTypes = {
  entry: CmsEntities.Content,
};

DealerCenterSectionHeader.defaultProps = {
  entry: DEFAULT_CONTENT,
};
