import {
  STATUS_ACTIVATED,
  STATUS_ELIGIBLE,
  STATUS_UNKNOWN,
  STATUS_INELIGIBLE,
} from 'site-modules/shared/constants/financing/digital-checkout';
import { ActivationFormEnrolled } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/activation-form-enrolled/activation-form-enrolled';
import { ActivationFormEligible } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/activation-form-eligible/activation-form-eligible';
import { ActivationFormInvalid } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/activation-form-invalid/activation-form-invalid';

/* Creative IDs */
export const DEALER_ACTIVATION_FORM = 'caramel-dealer-activation-form-module';
export const DEALER_UPDATE_FORM = 'caramel-dealer-update-form-module';
export const DEALER_DEACTIVATE_FORM = 'caramel-dealer-deactivation-form-module';
export const DEALER_REQUEST_FORM = 'caramel-dealer-request-form-module';

export const FORMS_MAP = {
  [STATUS_ACTIVATED]: { component: ActivationFormEnrolled },
  [STATUS_ELIGIBLE]: { component: ActivationFormEligible },
  [STATUS_UNKNOWN]: { component: ActivationFormInvalid },
  [STATUS_INELIGIBLE]: { component: ActivationFormInvalid },
};

export const INVALID_MODE = 'Invalid';
export const ENROLL_MODE = 'Enroll';
export const UPDATE_MODE = 'Update';
export const DEACTIVATE_MODE = 'Deactivate';
export const ACTIVATED_FORM_MODES = [UPDATE_MODE, DEACTIVATE_MODE];

export const DEACTIVATE_OPTION_OTHER = 'survey-option-other';

export const WUFOO_SURVEY_URL = 'https://tier3.wufoo.com/api/v3/forms/z4q4ix21q836ao/entries.json';
