import React from 'react';
import { isEmpty } from 'lodash';
import Container from 'reactstrap/lib/Container';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './dealer-center-fine-print.scss';

export function DealerCenterFinePrint({ entry }) {
  const metadata = entry.getAllMetadata();
  if (isEmpty(metadata)) return null;

  const { finePrintText } = metadata;

  return (
    <Container className="dealer-center-fine-print px-1_25 px-md-0" data-tracking-parent="dealercenter-fine-print">
      <ContentFragment classes="text-white">{finePrintText}</ContentFragment>
    </Container>
  );
}

DealerCenterFinePrint.propTypes = {
  entry: CmsEntities.Content,
};

DealerCenterFinePrint.defaultProps = {
  entry: DEFAULT_CONTENT,
};
