import React from 'react';
import PropTypes from 'prop-types';
import { AnimatedOnScrollElement } from 'site-modules/shared/components/animated-on-scroll-element/animated-on-scroll-element';

import './fade-in-slide-up.scss';

export function FadeInSlideUpOnScroll({ className, children }) {
  return (
    <AnimatedOnScrollElement className={className} animationClassNames={['fade-in', 'slide-up', 'fade-in-slide-up']}>
      {children}
    </AnimatedOnScrollElement>
  );
}

FadeInSlideUpOnScroll.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

FadeInSlideUpOnScroll.defaultProps = {
  className: '',
  children: null,
};
