import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from 'reactstrap/lib/Container';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ClippedContainer } from 'site-modules/shared/components/clipped-container/clipped-container';
import { DealerCenterButton } from 'site-modules/microsite/dealers/components/dealer-center-button/dealer-center-button';
import { DealerCenterMedia } from 'site-modules/microsite/dealers/components/dealer-center-media/dealer-center-media';
import { DealerCenterVideoControlButton } from 'site-modules/microsite/dealers/components/dealer-center-video-control-button/dealer-center-video-control-button';

import './dealer-center-hero-module.scss';

const MOBILE_MASK = {
  name: 'hero-mask',
  path: 'M0,0 H1 V0.92 C0.9,0.975,0.5,0.99,0,0.995Z',
};

const DEKSTOP_MASK = {
  name: 'hero-mask',
  path: 'M0,0 H1 V0.762 C0.899,0.764,0.827,0.86,0.753,0.91 C0.56,1.05,0.25,1.0,0,0.807Z',
};

const MOBILE_BACKGROUND_MASK = {
  name: 'background-mask',
  path: 'M0,0 H1 V0.93 C0.9,0.98,0.5,1,0,1Z',
};

const DESKTOP_BACKGROUND_MASK = {
  name: 'background-mask',
  path: 'M0,0 H1 V0.772 C0.899,0.784,0.827,0.88,0.753,0.93 C0.59,1.05,0.193,0.95,0,0.807Z',
};

export function DealerCenterHeroModuleUI({ entry, isMobile }) {
  const videoRef = useRef();

  const metadata = entry.getAllMetadata();

  if (isEmpty(metadata)) return null;

  const {
    header,
    subHeader,
    cta1Display,
    cta1Text,
    cta1URL,
    ctaClass,
    cta1Color,
    cta1NewTab,
    cta2Display,
    cta2Text,
    cta2URL,
    cta2Color,
    cta2NewTab,
    heroImageDesktop,
    heroImageMobile,
    headerVideo,
  } = metadata;

  const hasCta1 = cta1Display === 'true';
  const hasCta2 = cta2Display === 'true';

  return (
    <div className="dealer-center-hero-module-container pos-r mb-3">
      <div className="dealer-center-hero-module h-100 pos-r" data-tracking-parent="dealercenter-hero">
        <ClippedContainer
          mask={isMobile ? MOBILE_BACKGROUND_MASK : DESKTOP_BACKGROUND_MASK}
          containerClasses="w-100 h-100"
        >
          <div className="filled-background bg-blue-80 pos-a" />
          <ClippedContainer mask={isMobile ? MOBILE_MASK : DEKSTOP_MASK} containerClasses="w-100 h-100">
            <div className="gradient-overlay w-100 h-100 pos-a" />
            <DealerCenterMedia
              videoSrc={headerVideo}
              imageSrc={isMobile ? heroImageMobile : heroImageDesktop}
              videoClass="header-video w-100 h-100 pos-a"
              imageClass="header-image w-100 h-100 pos-a"
              videoRef={videoRef}
            />
            <Container fluid>
              <div className="content-container px-0_5 px-md-0">
                <svg className="d-block" width={0} height={0}>
                  <defs>
                    <clipPath id="tapered-content-box" clipPathUnits="objectBoundingBox">
                      <path d="M0,0 L0.991,0 Q1,0,1,0.023 L0.945,0.823 Q0.934,1,0.871,1 L0,1 L0,0" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="content py-2_5 px-md-2_5 pos-r">
                  <h1
                    className={classnames('size-36 font-weight-bold mb-1 text-white', isMobile ? 'size-30' : 'size-36')}
                  >
                    {header}
                  </h1>
                  {subHeader && (
                    <p className={classnames('text-white mb-1_25', isMobile ? 'size-20' : 'size-30')}>{subHeader}</p>
                  )}
                  <div className="d-flex">
                    {hasCta1 && (
                      <DealerCenterButton
                        href={cta1URL}
                        color={cta1Color}
                        className={classnames('px-1 py-0_5 border-0', ctaClass)}
                        data-tracking-id="hero_module_submit_primary"
                        ctaText={cta1Text}
                        target={cta1NewTab === 'true' ? '_blank' : '_self'}
                      />
                    )}
                    {hasCta2 && (
                      <DealerCenterButton
                        href={cta2URL}
                        color={cta2Color}
                        className={classnames('pos-r px-1 py-0_5 border-0', { 'ml-1': hasCta1 })}
                        data-tracking-id="hero_module_submit_secondary"
                        ctaText={cta2Text}
                        target={cta2NewTab === 'true' ? '_blank' : '_self'}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Container>
            {headerVideo && <DealerCenterVideoControlButton videoRef={videoRef} />}
          </ClippedContainer>
        </ClippedContainer>
      </div>
    </div>
  );
}

DealerCenterHeroModuleUI.propTypes = {
  entry: CmsEntities.Content,
  isMobile: PropTypes.bool,
};

DealerCenterHeroModuleUI.defaultProps = {
  entry: DEFAULT_CONTENT,
  isMobile: false,
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const DealerCenterHeroModule = connect(mapStateToProps)(DealerCenterHeroModuleUI);
