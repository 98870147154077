import React from 'react';
import PropTypes from 'prop-types';
import { AnimatedOnConditionElement } from 'site-modules/shared/components/animated-on-condition-element/animated-on-condition-element';

import './slam.scss';

export function SlamOnCondition({ className, children, shouldAnimate }) {
  return (
    <AnimatedOnConditionElement className={className} animationClassNames={['slam']} shouldAnimate={shouldAnimate}>
      {children}
    </AnimatedOnConditionElement>
  );
}

SlamOnCondition.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shouldAnimate: PropTypes.bool,
};

SlamOnCondition.defaultProps = {
  className: '',
  children: null,
  shouldAnimate: false,
};
