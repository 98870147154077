export const MicrositeTrackingConstant = {
  GLOBAL_NAV_CREATIVE_ID: 'dealercenter-global-nav',

  /* Action names */
  ACTION_CONTACT_EDMUNDS: 'contact_edmunds',
  ACTION_SIGN_IN: 'sign_in',
  ACTION_RESET_NAVIGATION: 'reset_navigation',
  ACTION_TOOL_SELECT: 'tool_select',
  ACTION_FILL_FORM: 'fill_form',
  ACTION_VIEW_CONTENT: 'view_content',

  /* Subaction names */
  CONTACT_US: 'contact_us',
  LOGIN: 'login',
  RESET_NAVIGATION: 'reset_navigation',
  TOOL_SELECT: 'tool_select',
  DEALER_ENROLLMENT_CONSENT: 'dealer_enrollment_consent',
  DEALER_DEACTIVATION_CONSENT: 'dealer_deactivation_consent',
  PRIMERY_ACTION_CLICK: 'primary_action_click',
  CARAMEL_SITE_CLICKOUT: 'caramel_site_clickout',
};
