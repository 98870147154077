import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { CmsEntities } from 'client/data/models/cms';
import {
  ARROW,
  ARROW_WHITE,
  ARROW_MOBILE,
  ARROW_WHITE_MOBILE,
} from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-arrows';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './digital-checkout-how-it-works.scss';

export const DigitalCheckoutHowItWorksUI = ({ entry, isMobile }) => {
  const metadata = entry.getAllMetadata();

  if (isEmpty(metadata)) return null;

  const { logo, header } = metadata;
  const bullets = entry.children();
  const firstStepData = bullets[0].getAllMetadata();

  return (
    <div className="how-it-works-wrapper pos-r mt-2">
      <Container className="pt-2" data-tracking-parent="caramel-how-it-works">
        <h2 className="text-white mb-2 size-36 text-center">{header}</h2>
        <Row className="d-flex justify-content-between mt-1_75 px-1 px-md-0">
          <Col xs={12} md={3} className="first-step-wrapper bg-white pr-1 py-1_5 pl-1_5">
            <div className="image-container mb-1 d-flex align-items-baseline">
              <span className="text-cool-gray-30 step-number mr-1">1</span>
              <img src={firstStepData.imageSrc} alt="" aria-hidden />
            </div>
            <h3 className="text-cool-gray-30 size-20 font-weight-bold">{firstStepData.title}</h3>
            <div className="">{firstStepData.description}</div>
            {isMobile && ARROW_WHITE_MOBILE}
          </Col>

          <Col xs={12} md={9} className="steps-wrapper p-0">
            <Row className="m-0">
              <Col className="p-0">
                <div className="logo-container pt-3 pt-md-1_5 pb-1_5 px-1_5 bg-white">
                  <img className="d-block mx-auto" src={logo} alt="" aria-hidden />
                </div>
              </Col>
            </Row>

            <Row className="m-0 steps">
              {bullets.map((bullet, index) => {
                const { title, description } = bullet.getAllMetadata();
                if (index > 0) {
                  return (
                    <Col xs={12} md={4} className="step pr-1_5 pb-1_5 pt-2 pl-2 pos-r" key={`step-${index + 1}`}>
                      {index === 1 && !isMobile && ARROW_WHITE}
                      {index < bullets.length - 1 && isMobile && ARROW_MOBILE}
                      {index !== 1 && !isMobile && ARROW}
                      <div className="text-cool-gray-30 step-number text-center mb-1 mt-0_25 mt-0_25">{index + 1}</div>
                      <h3 className="text-cool-gray-30 size-20 font-weight-bold mb-0_5">{title}</h3>
                      <ContentFragment>{description}</ContentFragment>
                    </Col>
                  );
                }
                return null;
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

DigitalCheckoutHowItWorksUI.propTypes = {
  entry: CmsEntities.Content.isRequired,
  isMobile: PropTypes.bool,
};

DigitalCheckoutHowItWorksUI.defaultProps = {
  isMobile: false,
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const DigitalCheckoutHowItWorks = connect(mapStateToProps)(DigitalCheckoutHowItWorksUI);
