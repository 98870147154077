import React from 'react';
import PropTypes from 'prop-types';
import { AnimatedOnConditionElement } from 'site-modules/shared/components/animated-on-condition-element/animated-on-condition-element';
import { AnimatedOnScrollElement } from 'site-modules/shared/components/animated-on-scroll-element/animated-on-scroll-element';

import './slide-right.scss';

export function SlideRightOnCondition({ className, children, shouldAnimate }) {
  return (
    <AnimatedOnConditionElement
      className={className}
      animationClassNames={['slide-right']}
      shouldAnimate={shouldAnimate}
    >
      {children}
    </AnimatedOnConditionElement>
  );
}

SlideRightOnCondition.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shouldAnimate: PropTypes.bool,
};

SlideRightOnCondition.defaultProps = {
  className: '',
  children: null,
  shouldAnimate: false,
};

export function SlideRightOnScroll({ className, children }) {
  return (
    <AnimatedOnScrollElement className={className} animationClassNames={['slide-right']}>
      {children}
    </AnimatedOnScrollElement>
  );
}

SlideRightOnScroll.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

SlideRightOnScroll.defaultProps = {
  className: '',
  children: null,
};
