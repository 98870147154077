import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

export const EligibleFormBullets = ({ bullets, bulletsHeader }) => (
  <Fragment>
    <h3 className="size-30 text-white font-weight-bold mb-1_25 mt-2_5 mt-md-0">{bulletsHeader}</h3>
    <ul className="text-white pl-2 size-20">
      {bullets.map((bullet, index) => {
        const { copy } = bullet.getAllMetadata();
        return (
          <li key={`enroll-form-bullet_${index + 1}`} className="mb-1">
            <ContentFragment>{copy}</ContentFragment>
          </li>
        );
      })}
    </ul>
  </Fragment>
);

EligibleFormBullets.propTypes = {
  bullets: PropTypes.arrayOf(PropTypes.shape({})),
  bulletsHeader: PropTypes.string,
};
EligibleFormBullets.defaultProps = {
  bullets: [],
  bulletsHeader: '',
};
