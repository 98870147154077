import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';

export const ActivationFormButton = ({ cta }) => (
  <Button
    type="submit"
    className="activation-form-btn size-20 font-weight-medium text-capitalize mt-1_5 mb-1_25 rounded border-0 text-white w-100"
    block
    data-testid="enroll-btn"
  >
    {cta}
  </Button>
);

ActivationFormButton.propTypes = {
  cta: PropTypes.string,
};
ActivationFormButton.defaultProps = {
  cta: '',
};
