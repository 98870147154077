import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useScrollIntoViewAnimation } from 'site-modules/shared/hooks/use-scroll-into-view-animation';

export function AnimatedOnScrollElement({ tag, className, children, animationClassNames }) {
  const Element = tag;
  const ref = useRef(null);

  useScrollIntoViewAnimation({
    animationClassNames,
    ref,
  });

  return (
    <Element className={className} ref={ref}>
      {children}
    </Element>
  );
}

AnimatedOnScrollElement.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  animationClassNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AnimatedOnScrollElement.defaultProps = {
  tag: 'div',
  className: '',
  children: null,
};
