import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classnames from 'classnames';
import { Checkbox } from 'site-modules/shared/components/checkbox/checkbox';
import { Link } from 'site-modules/shared/components/link/link';

export const ActivationFormCheckbox = ({
  setIsChecked,
  copy,
  dataTrackingId,
  isChecked,
  classNames,
  contentClassNames,
  privacyLink,
  privacyLinkText,
  isError,
  setIsError,
}) => {
  const handleCheckboxClick = useCallback(() => {
    setIsChecked(!isChecked);
    setIsError(false);
  }, [isChecked, setIsChecked, setIsError]);

  return (
    <Checkbox
      tabIndex={0}
      id="agreement"
      onChange={handleCheckboxClick}
      containerClassName="mt-1 d-flex"
      className={classnames({
        'custom-input-danger': isError,
      })}
      customLabelClassName={classnames(
        'checkbox checkbox-icon',
        classNames,
        isChecked ? 'icon-checkbox-checked3 text-primary' : 'icon-checkbox-unchecked3'
      )}
      data-tracking-id={dataTrackingId}
      data-tracking-value={`${copy}${privacyLinkText}`}
    >
      <div className={classnames('text-left', contentClassNames)}>
        {copy}{' '}
        {privacyLink && (
          <Link
            className="text-underline"
            to={`${window.location.origin.replace('dealers', 'www')}/${privacyLink}`}
            data-tracking-id="enrollment_agreement_info"
            target="_blank"
          >
            {privacyLinkText}
          </Link>
        )}
      </div>
    </Checkbox>
  );
};

ActivationFormCheckbox.propTypes = {
  setIsChecked: PropTypes.func,
  isChecked: PropTypes.bool,
  copy: PropTypes.string,
  privacyLink: PropTypes.string,
  privacyLinkText: PropTypes.string,
  dataTrackingId: PropTypes.string,
  classNames: PropTypes.string,
  contentClassNames: PropTypes.string,
  isError: PropTypes.bool,
  setIsError: PropTypes.func,
};
ActivationFormCheckbox.defaultProps = {
  setIsChecked: noop,
  isChecked: false,
  copy: '',
  privacyLink: undefined,
  privacyLinkText: '',
  dataTrackingId: '',
  classNames: '',
  contentClassNames: '',
  isError: false,
  setIsError: noop,
};
