import React, { useRef } from 'react';
import { isEmpty } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { EmbeddedYoutubeAsync } from 'site-modules/shared/components/video/embedded-youtube/embedded-youtube-async';
import { VIDEO_CAROUSEL_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';

import './dealer-center-video-module.scss';

export const DealerCenterVideoModule = ({ entry }) => {
  const metadata = entry.getAllMetadata();
  const { videoId, videoTitle, videoDescription } = metadata;
  const isEmptyMetadata = isEmpty(metadata);
  const ref = useRef();

  useScrollIntoViewTracking({
    eventData: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.VIDEO_DISPLAYED,
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        creative_id: VIDEO_CAROUSEL_CREATIVE_ID,
        value: videoTitle,
      },
    },
    ref,
  });

  if (isEmptyMetadata) return null;

  const getEventDataForPlayPauseClick = subaction => ({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_name: TrackingConstant.ACTION_VIEW_CONTENT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: subaction,
      creative_id: VIDEO_CAROUSEL_CREATIVE_ID,
      value: videoTitle,
    },
  });

  function changeHandler(event) {
    const playerState = window.YT.PlayerState;
    switch (event.data) {
      case playerState.PLAYING: {
        EventToolbox.fireTrackAction(getEventDataForPlayPauseClick(TrackingConstant.VIDEO_START));
        break;
      }
      case playerState.PAUSED: {
        EventToolbox.fireTrackAction(getEventDataForPlayPauseClick(TrackingConstant.VIDEO_PAUSE));
        break;
      }
      default: {
        // Do nothing
      }
    }
  }

  return (
    <div className="dealer-center-video-module pos-r" data-tracking-parent={VIDEO_CAROUSEL_CREATIVE_ID} ref={ref}>
      <figure className="dealer-center-video-module-background pos-a w-100 m-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 650"
          fill="none"
          preserveAspectRatio="none"
          style={{ height: '100%', width: '100%' }}
          aria-hidden
        >
          <defs>
            <linearGradient id="video-background-gradient-arc-1">
              <stop offset="0%" style={{ stopColor: '#00DFFF', stopOpacity: 0.25 }} />
              <stop offset="100%" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
            </linearGradient>
            <linearGradient id="video-background-gradient-arc-2">
              <stop offset="0%" style={{ stopColor: '#FFFFFF', stopOpacity: 0 }} />
              <stop offset="100%" style={{ stopColor: '#00DFFF', stopOpacity: 0.25 }} />
            </linearGradient>
          </defs>
          <path
            fill="url(#video-background-gradient-arc-1)"
            d="M 0,25 C 200,50 800,50 1000,25 V 625 C 800,650 200,650 0,625 Z"
          />
          <path
            fill="url(#video-background-gradient-arc-2)"
            d="M 0,25 C 200,0 800,0 1000,25 V 625 C 800,600 200,600 0,625 Z"
          />
        </svg>
      </figure>
      <div className="dealer-center-video-container mx-1_75 mx-lg-auto pos-r">
        <h2 className="dealer-center-video-title text-white text-center font-weight-bold mb-2_5">{videoTitle}</h2>
        <div className="dealer-center-video-player overflow-hidden mx-auto mb-2_5 px-lg-3">
          <EmbeddedYoutubeAsync
            videoId={videoId}
            videoTitle={videoTitle}
            creativeId={VIDEO_CAROUSEL_CREATIVE_ID}
            autoplay={false}
            changeHandler={changeHandler}
          />
        </div>
        {videoDescription && (
          <div className="dealer-center-video-description size-20 text-white text-center mb-1">{videoDescription}</div>
        )}
      </div>
    </div>
  );
};

DealerCenterVideoModule.propTypes = {
  entry: CmsEntities.Content,
};

DealerCenterVideoModule.defaultProps = {
  entry: null,
};
