import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, noop } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
/* Models */
import { CmsEntities } from 'client/data/models/cms';
/* Utils */
import { getEmailTokens } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-helper';
import { sendMarketoEmailReport } from 'client/data/utils/marketo-service';
/* Constants */
import { DEALER_ACTIVATION_FORM, ENROLL_MODE } from 'site-modules/microsite/dealers/constants/digital-checkout';
import { TrackingConstant } from 'client/tracking/constant';
/* Components */
import { ActivationFormWrapper } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/activation-form-wrapper/activation-form-wrapper';
import { EligibleFormBullets } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/eligible-form-bullets/eligible-form-bullets';
import { SuccessMessage } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/success-message/success-message';

export const ActivationFormEligible = ({ entry, dealer, location, handleSubmit, showSuccess }) => {
  const metadata = entry.getAllMetadata();

  if (isEmpty(metadata)) return null;

  const { bulletsHeader, thankYouHeader, thankYouText, formHeader } = metadata;
  const bullets = entry.children();

  const submitCallback = async fields => {
    await handleSubmit({
      trackingData: {
        value: 'Enroll Now',
        subactionName: TrackingConstant.SUBMIT_ENROLLMENT,
        creativeId: DEALER_ACTIVATION_FORM,
      },
      fields,
      mode: ENROLL_MODE,
    });

    await sendMarketoEmailReport({
      data: {
        'my.emailData': getEmailTokens({ location }),
      },
      campaignName: 'caramel_dealer_welcome',
      email: fields.dealerContactEmail,
    });
  };

  return (
    <Row
      className="digital-checkout-activation-form-wrapper align-items-center px-0_5 px-md-0"
      data-tracking-parent={DEALER_ACTIVATION_FORM}
    >
      <Col xs={12} md={5} className="pr-md-1_25">
        <EligibleFormBullets bullets={bullets} bulletsHeader={bulletsHeader} />
      </Col>
      <Col xs={12} md={7} className="pl-md-1_25">
        <div
          className={classNames('text-white text-center digital-checkout-activation-form', {
            'px-1_5 px-md-1_25 py-2_5 py-md-1_25 d-flex align-items-center': showSuccess,
            'p-1_25': !showSuccess,
          })}
        >
          {showSuccess ? (
            <SuccessMessage thankYouHeader={thankYouHeader} thankYouText={thankYouText} />
          ) : (
            <>
              <h2 className="size-24 text-cool-gray-10 mb-1_5">{formHeader}</h2>
              <ActivationFormWrapper
                dealer={dealer}
                location={location}
                dataTrackingParent={DEALER_ACTIVATION_FORM}
                submitCallback={submitCallback}
                metadata={metadata}
              />
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

ActivationFormEligible.propTypes = {
  entry: CmsEntities.Content.isRequired,
  dealer: PropTypes.shape({}),
  location: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  showSuccess: PropTypes.bool,
};

ActivationFormEligible.defaultProps = {
  dealer: {},
  location: {},
  handleSubmit: noop,
  showSuccess: false,
};
