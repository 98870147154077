import { get, isEmpty } from 'lodash';
/* Utils */
import { withMetrics } from 'client/data/api/api-metrics';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { ClientConfig } from 'client/configuration';
import { DealerInventoryModel } from 'client/data/models/dealer-inventory';
import { getDealStatus } from 'site-modules/shared/utils/financing/caramel-utils';
/* Constants */
import {
  STATUS_ACTIVATED,
  STATUS_ELIGIBLE,
  STATUS_INELIGIBLE,
  STATUS_UNKNOWN,
} from 'site-modules/shared/constants/financing/digital-checkout';
/* Models */
import { InventoryModel } from 'client/data/models/inventory';

export const MODEL = {
  DEALERS: 'rooftopIds["{rooftopId}"].dealer',
  DEALERS_WITHOUT_VALIDATION: 'vins["{vin}"].dealerWithoutValidation',
};
export const PATHS = {
  DEALERS: ({ rooftopId }) => (rooftopId ? `rooftopIds["${rooftopId}"].dealer` : ''),
  DEALERS_WITHOUT_VALIDATION: ({ vin }) => (vin ? `vins["${vin}"].dealerWithoutValidation` : ''),
};

export const DigitalCheckoutModel = createModelSegment('digitalCheckout', [
  {
    path: MODEL.DEALERS,
    async resolve({ rooftopId }, context) {
      if (rooftopId === 'undefined') return {};
      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/dealer/v5/dealership/${rooftopId}/franchises?make=Used`)
        .then(response => {
          const dealerInfo = get(response, 'franchises[0]', {});
          const franchiseId = get(dealerInfo, 'id');
          const isValidDealer = get(dealerInfo, 'productFeatures.active', false);
          const dealer = {
            dealerName: dealerInfo?.name,
            dealerZipCode: dealerInfo?.address.zip,
            franchiseId,
          };

          if (isValidDealer) {
            return context
              .resolveValue(`dealerInventory.id.${rooftopId}`, DealerInventoryModel)
              .then(dealerInventory => {
                const isEligibleDealer = !!get(dealerInventory, 'rooftopCounters.total');

                if (isEligibleDealer) {
                  const options = {
                    method: 'GET',
                    retries: 0,
                    headers: {
                      'content-type': 'application/json',
                      'x-api-key': ClientConfig.get('carBuying').caramelApiKey,
                      'Cache-Control': 'no-cache',
                    },
                    timeout: ClientConfig.get('carBuying').apiDefaultTimeoutMs,
                  };

                  return withMetrics(EdmundsAPI, context)
                    .fetchJson(`/car-buying/v1/caramel/enrollment/${franchiseId}`, options)
                    .then(res => ({
                      ...(isEmpty(res) ? dealer : res),
                      dealerStatus: get(res, 'caramelOptIn') ? STATUS_ACTIVATED : STATUS_ELIGIBLE,
                    }))
                    .catch(() => ({ ...dealer, dealerStatus: STATUS_ELIGIBLE }));
                }
                return { ...dealer, dealerStatus: STATUS_INELIGIBLE };
              });
          }
          return { dealerStatus: STATUS_UNKNOWN };
        })
        .catch(() => ({
          dealerStatus: STATUS_UNKNOWN,
        }));
    },
  },

  {
    path: MODEL.DEALERS_WITHOUT_VALIDATION,
    async resolve({ vin }, context) {
      const vinData = await context.resolveValue(`vin["${vin}"]`, InventoryModel);
      const franchiseId = vinData.dealerInfo?.franchiseId;
      const options = {
        method: 'GET',
        retries: 0,
        headers: {
          'content-type': 'application/json',
          'x-api-key': ClientConfig.get('carBuying').caramelApiKey,
        },
        timeout: ClientConfig.get('carBuying').apiDefaultTimeoutMs,
      };

      try {
        const caramelAPIUrl = `/car-buying/v1/caramel/enrollment/${franchiseId}?vin=${vin}`;
        const response = await withMetrics(EdmundsAPI, context).fetchJson(caramelAPIUrl, options);
        const existingDealId = response?.existingDealId;
        if (existingDealId) {
          try {
            const dealStatusAPIUrl = `/car-buying/v1/caramel/deal-status/${response?.dealId}`;
            const dealStatusResponse = await withMetrics(EdmundsAPI, context).fetchJson(dealStatusAPIUrl, options);

            if (dealStatusResponse?.buyerSimpleStatus || dealStatusResponse?.sellerSimpleStatus) {
              return { ...response, dealStatus: getDealStatus(dealStatusResponse?.buyerSimpleStatus) };
            }
          } catch {
            return response;
          }
        }
        return response;
      } catch {
        return {};
      }
    },
  },
]);
