import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PlayPauseButton } from 'site-modules/shared/components/play-pause-button/play-pause-button';

export const DealerCenterVideoControlButton = ({ videoRef }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);

  const playPauseVideo = useCallback(() => {
    if (videoRef && videoRef.current) {
      const nextIsVideoPlaying = !isVideoPlaying;
      setIsVideoPlaying(nextIsVideoPlaying);

      if (nextIsVideoPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [videoRef, isVideoPlaying]);

  return (
    <PlayPauseButton
      isPlaying={isVideoPlaying}
      onClick={playPauseVideo}
      iconClassName="size-20 text-white"
      trackingId="play_pause"
      // Tracking pixel is sent after isVideoPlaying value is already altered
      trackingValue={isVideoPlaying ? 'Play' : 'Pause'}
    />
  );
};

DealerCenterVideoControlButton.propTypes = {
  videoRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
};
