import React, { useState, useCallback, useRef, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT, getNameIndexMetadata } from 'client/data/cms/content';
import { InfiniteAnimatedScroller } from 'site-modules/shared/components/infinite-animated-scroller/infinite-animated-scroller';
import { PlayPauseButton } from 'site-modules/shared/components/play-pause-button/play-pause-button';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';
import { TrackingConstant } from 'client/tracking/constant';
import { PARTNERSHIPS_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';

import './dealer-center-partherships-module.scss';

export function DealerCenterPartnershipsModule({ entry }) {
  const metadata = entry.getAllMetadata();
  const [isPlaying, setIsPlaying] = useState(true);
  const ref = useRef();

  // Prevent useScrollIntoViewTracking() from firing on each component re-render
  const eventData = useMemo(
    () => ({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.PARTNERSHIPS_MODULE_DISPLAYED,
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        creative_id: PARTNERSHIPS_CREATIVE_ID,
        value: metadata.headerText,
      },
    }),
    [metadata.headerText]
  );

  const onPlayPauseClick = useCallback(() => {
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  useScrollIntoViewTracking({
    eventData,
    ref,
    additionalConditions: !isEmpty(metadata),
  });

  if (isEmpty(metadata)) return null;

  const { headerText } = metadata;
  const partners = getNameIndexMetadata(entry, 'imageURL', false);

  return (
    <div className="partnerships-module" data-tracking-parent={PARTNERSHIPS_CREATIVE_ID} ref={ref}>
      <h2 className="header text-white text-center font-weight-bold mb-2_5">{headerText}</h2>
      <div className="partners-list bg-blue-100 d-flex align-items-center pos-r">
        <InfiniteAnimatedScroller animationDuration={partners.length * 3} isPlaying={isPlaying}>
          {partners.map(partnerImg => (
            <img key={partnerImg} src={partnerImg} alt="Partner logo" className="partner-logo px-2" height={100} />
          ))}
        </InfiniteAnimatedScroller>
        <PlayPauseButton
          isPlaying={isPlaying}
          onClick={onPlayPauseClick}
          size={30}
          iconClassName="size-14 text-blue-50"
          trackingId="play_pause"
          // Tracking pixel is sent after isPlaying value is already altered
          trackingValue={isPlaying ? 'Play' : 'Pause'}
        />
      </div>
    </div>
  );
}

DealerCenterPartnershipsModule.propTypes = {
  entry: CmsEntities.Content,
};

DealerCenterPartnershipsModule.defaultProps = {
  entry: DEFAULT_CONTENT,
};
