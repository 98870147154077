import { get } from 'lodash';
import { EDMUNDS_ENROLLMENT_SOURCE } from 'site-modules/shared/constants/financing/digital-checkout';
import { DEACTIVATE_MODE, ENROLL_MODE } from 'site-modules/microsite/dealers/constants/digital-checkout';

export function getCaramelAPIPayload({ dealer, location, fields, mode }) {
  return {
    caramelOptIn: mode !== DEACTIVATE_MODE,
    ...(mode === DEACTIVATE_MODE
      ? {
          dealerContactFirstName: dealer?.dealerContactFirstName,
          dealerContactLastName: dealer?.dealerContactLastName,
          dealerContactEmail: dealer?.dealerContactEmail,
          dealerPhone: dealer?.dealerPhone,
        }
      : {
          dealerContactFirstName: fields?.dealerContactFirstName,
          dealerContactLastName: fields?.dealerContactLastName,
          dealerContactEmail: fields?.dealerContactEmail,
          dealerPhone: fields?.dealerPhone,
        }),
    ...(mode === ENROLL_MODE && {
      dealerName: dealer?.dealerName,
      dealerState: location?.state,
      dealerZipCode: dealer?.dealerZipCode,
      franchiseId: get(dealer, 'franchiseId', '').toString(),
      enrollmentSource: EDMUNDS_ENROLLMENT_SOURCE,
    }),
  };
}

export const getSurveyParams = ({ fields, dealer }) => ({
  Field1: fields?.dealerContactFirstName,
  Field2: fields?.dealerContactLastName,
  Field3: fields?.dealerContactEmail,
  Field7: fields?.dealerPhone,
  Field4: fields?.dealerName,
  Field5: fields?.dealerZipCode,
  Field9: get(dealer, 'franchiseId', ''),
});
