import React, { useRef } from 'react';
import { CmsEntities } from 'client/data/models/cms';
import { TrackingConstant } from 'client/tracking/constant';
import { RICH_TEXT_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { FadeInSlideUpOnScroll } from 'site-modules/shared/components/animated/fade-in-slide-up';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';

import './dealer-center-rich-text-module.scss';

const getAlignmentClassName = (alignment, shouldAlignTextCenter = true, shouldAlignTextRight = true) => {
  if (!alignment) {
    return '';
  }

  const alignmentLowerCase = alignment.toLowerCase();
  if (alignmentLowerCase === 'center') {
    return `align-self-center${shouldAlignTextCenter ? ' text-center' : ''}`;
  }
  if (alignmentLowerCase === 'right') {
    return `align-self-end${shouldAlignTextRight ? ' text-right' : ''}`;
  }

  return '';
};

export const DealerCenterRichTextModule = ({ entry }) => {
  const containerRef = useRef(null);

  const metadata = entry.getAllMetadata();
  const { headerText, headerTextAlign, subheaderText, subheaderTextAlign, regularText, regularTextAlign } =
    metadata || {};

  const shouldRender = !!headerText && !!regularText;

  useScrollIntoViewTracking({
    eventData: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.SUBACTION_RICH_TEXT_MODULE_DISPLAYED,
        creative_id: RICH_TEXT_CREATIVE_ID,
        value: headerText,
      },
    },
    additionalConditions: shouldRender,
    ref: containerRef,
  });

  if (!shouldRender) {
    return null;
  }

  return (
    <div
      className="dealer-center-rich-text-module d-flex flex-column text-white"
      data-tracking-parent={RICH_TEXT_CREATIVE_ID}
      ref={containerRef}
    >
      <FadeInSlideUpOnScroll className={getAlignmentClassName(headerTextAlign)}>
        <ContentFragment classes="header font-weight-bold size-36">{headerText}</ContentFragment>
      </FadeInSlideUpOnScroll>
      {subheaderText && (
        <FadeInSlideUpOnScroll className={getAlignmentClassName(subheaderTextAlign)}>
          <ContentFragment classes="subheader font-weight-medium size-24 mt-1_25">{subheaderText}</ContentFragment>
        </FadeInSlideUpOnScroll>
      )}
      <FadeInSlideUpOnScroll className={getAlignmentClassName(regularTextAlign, false)}>
        <ContentFragment classes="rich-text mt-2_5">{regularText}</ContentFragment>
      </FadeInSlideUpOnScroll>
    </div>
  );
};

DealerCenterRichTextModule.propTypes = {
  entry: CmsEntities.Content.isRequired,
};
