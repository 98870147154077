import React from 'react';
import PropTypes from 'prop-types';
import Col from 'reactstrap/lib/Col';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './dealer-center-feature-grid-item.scss';

export const DealerCenterFeatureGridItem = ({ entry, className }) => {
  const metadata = entry.getAllMetadata();

  if (isEmpty(metadata)) {
    return null;
  }

  const { itemTitle, itemImage, itemDescription } = metadata;

  return (
    <Col
      xs={12}
      md={4}
      className={classnames('dealer-center-feature-grid-item d-flex flex-column bg-white text-center p-0', className)}
    >
      <div className="item-image-container overflow-hidden w-100">
        <img src={itemImage} aria-hidden alt="" className="item-image w-100 h-100" />
      </div>
      <ContentFragment classes="item-title font-weight-bold size-24 pt-1_25 pb-1 px-1_25">{itemTitle}</ContentFragment>
      <ContentFragment classes="item-description size-20 pb-1_25 px-1_25">{itemDescription}</ContentFragment>
    </Col>
  );
};

DealerCenterFeatureGridItem.propTypes = {
  entry: CmsEntities.Content.isRequired,
  className: PropTypes.string,
};

DealerCenterFeatureGridItem.defaultProps = {
  className: '',
};
