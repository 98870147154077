import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';
import dateFormat from 'dateformat';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { obscureEmail } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-helper';

export const ActivationFormDealerInfo = ({ dealer, isDeactivated }) => (
  <div className="text-white mb-1_5 mb-md-0">
    <Row className="mb-1">
      <Col xs={5} className="pl-md-2">
        <div className="font-weight-bold">Status:</div>
      </Col>
      <Col xs={7}>
        <div className="size-20 font-weight-bold">{isDeactivated ? 'Deactivated' : 'Activated'}</div>
        <div>{`Caramel is ${isDeactivated ? 'not currently' : 'currently activated'} on your Edmunds listings.`}</div>
      </Col>
    </Row>
    <Row className="mb-1">
      <Col xs={5} className="pl-md-2">
        <div className="font-weight-bold">{isDeactivated ? 'Deactivated:' : 'Activated On:'}</div>
      </Col>
      <Col xs={7}>
        <div>{isDeactivated ? dateFormat(Date.now(), 'm/d/yyyy') : get(dealer, 'updatedAt', '').split(' ')[0]}</div>
      </Col>
    </Row>

    <Row className={classNames({ invisible: isDeactivated })}>
      <Col xs={5} className="pl-md-2">
        <div className="font-weight-bold">Activated By:</div>
      </Col>
      <Col xs={7}>
        <div>
          {get(dealer, 'dealerContactFirstName', '')} {get(dealer, 'dealerContactLastName', '')}
        </div>
        <div>{obscureEmail(get(dealer, 'dealerContactEmail', ''))}</div>
      </Col>
    </Row>
  </div>
);

ActivationFormDealerInfo.propTypes = {
  dealer: PropTypes.shape({}),
  isDeactivated: PropTypes.bool,
};
ActivationFormDealerInfo.defaultProps = {
  dealer: {},
  isDeactivated: false,
};
