import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT, getNameIndexMetadata } from 'client/data/cms/content';
import { TrackingConstant } from 'client/tracking/constant';
import { VERSUS_OTHERS_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';
import { useOnScreen } from 'client/site-modules/shared/hooks/use-on-screen';
import { SlamOnCondition } from 'client/site-modules/shared/components/animated/slam';
import { SlideRightOnCondition } from 'site-modules/shared/components/animated/slide-right';

import './dealer-center-edmunds-vs-others-module.scss';

const VsOthersSection = ({ bigText, smallText, shouldAnimate, orderNum }) => (
  <section style={{ '--order-num': orderNum }} className="vs-others-section text-white text-center py-2_5">
    <SlideRightOnCondition shouldAnimate={shouldAnimate}>
      <p className="font-weight-normal size-24 mb-1">{smallText}</p>
    </SlideRightOnCondition>
    <SlamOnCondition shouldAnimate={shouldAnimate}>
      <p className="big-text font-weight-bold mb-0">{bigText}</p>
    </SlamOnCondition>
  </section>
);

VsOthersSection.propTypes = {
  bigText: PropTypes.string.isRequired,
  smallText: PropTypes.string.isRequired,
  orderNum: PropTypes.number.isRequired,
  shouldAnimate: PropTypes.bool.isRequired,
};

export function DealerCenterEdmundsVsOthersModule({ entry }) {
  const [isAnimating, setIsAnimating] = useState(false);
  const metadata = entry.getAllMetadata();
  const ref = useRef();
  const listRef = useRef();
  const [isVisible] = useOnScreen(listRef);

  const { headerText } = metadata;

  useScrollIntoViewTracking({
    eventData: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.VERSUS_OTHERS_DISPLAYED,
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        creative_id: VERSUS_OTHERS_CREATIVE_ID,
        value: headerText,
      },
    },
    ref,
    additionalConditions: !isEmpty(metadata),
  });

  useEffect(() => {
    if (isVisible) {
      setIsAnimating(true);
    }
  }, [isVisible]);

  if (isEmpty(metadata)) return null;

  const bigTexts = getNameIndexMetadata(entry, 'bigText', false);
  const smallTexts = getNameIndexMetadata(entry, 'smallText', false);

  const texts = bigTexts.map((el, ind) => ({
    bigText: el,
    smallText: smallTexts[ind],
    id: ind,
  }));

  return (
    <div
      className="dealer-center-edmunds-vs-others-module pos-r pb-2_5 px-0_5"
      data-tracking-parent={VERSUS_OTHERS_CREATIVE_ID}
      ref={ref}
    >
      <figure className="vs-others-module-background pos-a w-100 h-100 m-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 650"
          fill="none"
          preserveAspectRatio="none"
          className="h-100 w-100"
          aria-hidden
        >
          <defs>
            <linearGradient
              id="paint0_linear_5041_29608"
              x1="-7.3446e-05"
              y1={-438.636}
              x2={754.097}
              y2={363.069}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity={0.5} />
              <stop offset={1} stopColor="#00DFFF" stopOpacity={0} />
              <linearGradient
                id="paint0_linear_5041_29607"
                x1={718.5}
                y1={546}
                x2={1418.53}
                y2={-998.268}
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#00DFFF" stopOpacity={0} />
                <stop offset={1} stopColor="white" stopOpacity={0.5} />
              </linearGradient>
            </linearGradient>
          </defs>
          <path
            d="M0 0C617 57.5844 1200 0 1200 0V1048.33C757.5 1091.89 494.758 1091.89 0 1048.33V0Z"
            fill="url(#paint0_linear_5041_29608)"
          />
          <path
            d="M0 1092C617 1033.83 1200 1092 1200 1092V33.0005C757.5 -11.0001 494.758 -11.0001 0 33.0005V1092Z"
            fill="url(#paint0_linear_5041_29607)"
          />
        </svg>
      </figure>
      <div className="content pos-r">
        <h2 className="header text-white text-center font-weight-bold mb-0">{headerText}</h2>
        <div ref={listRef} className={classnames({ 'is-animating': isAnimating })}>
          {texts.map(({ bigText, smallText, id }, ind) => (
            <VsOthersSection
              key={id}
              bigText={bigText}
              smallText={smallText}
              orderNum={ind + 1}
              shouldAnimate={isAnimating}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

DealerCenterEdmundsVsOthersModule.propTypes = {
  entry: CmsEntities.Content,
};

DealerCenterEdmundsVsOthersModule.defaultProps = {
  entry: DEFAULT_CONTENT,
};
