export const DIGITAL_CHECKOUT_EVENTS = {
  CREATE_DEAL: 'digital_checkout.create_deal', // TODO: should be removed when clean up TED-6468-Caramel-Checkout-V2
  DEALER_STATUS: 'digital_checkout.dealer_status',
  ENROLL_STATUS: 'digital_checkout.enroll_status',
  INPUT_VALIDATION: 'digital_checkout.input_validation',
  SUBMIT_ATTEMPTS: 'digital_checkout.submit_attempts',
  DETAILS: 'digital_checkout.details',
  MANAGE_FORM_TOGGLE: 'digital_checkout.manage_form_toggle',
  SUBMIT_SURVEY: 'digital_checkout.submit_survey',
  WIDGET_VIEW: 'digital_checkout.widget_view',
  SURVEY_API_RESPONSE: 'digital_checkout.survey_api_response',
};
