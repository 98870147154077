import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import classNames from 'classnames';

import './radio-button.scss';

export function RadioButton({
  handleChange,
  id,
  isChecked,
  name,
  specialClass,
  containerClass,
  dataTrackingId,
  disabled,
}) {
  return (
    <div className={classNames('radio-container', containerClass)}>
      <Input
        className={specialClass}
        name={name}
        onChange={handleChange}
        id={id}
        data-testid={id}
        type="radio"
        value={id}
        checked={isChecked}
        data-tracking-id={dataTrackingId}
        disabled={disabled}
      />
      <span className={classNames('checkmark rounded-circle bg-white', { disabled })} />
    </div>
  );
}

RadioButton.propTypes = {
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  specialClass: PropTypes.string,
  containerClass: PropTypes.string,
  dataTrackingId: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  specialClass: '',
  name: '',
  isChecked: false,
  containerClass: '',
  dataTrackingId: null,
  disabled: false,
};
