import React from 'react';
import PropTypes from 'prop-types';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { get } from 'lodash';
import {
  obscureEmail,
  obscurePhone,
} from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-helper';
import { DEALER_DEACTIVATE_FORM } from 'site-modules/microsite/dealers/constants/digital-checkout';

export const DeactivationFormView = ({ dealer, children }) => (
  <div className="deactivation-form-wrapper p-1_25 my-2 text-left" data-tracking-parent={DEALER_DEACTIVATE_FORM}>
    <Row className="mb-1">
      <Col>
        <div className="size-14 text-cool-gray-30">Dealer Name</div>
        <div className="size-20 text-cool-gray-10">{get(dealer, 'dealerName')}</div>
      </Col>
    </Row>
    <Row className="mb-1">
      <Col xs={6}>
        <div className="size-14 text-cool-gray-30">First Name</div>
        <div className="size-20 text-cool-gray-10">{get(dealer, 'dealerContactFirstName')}</div>
      </Col>
      <Col xs={6}>
        <div className="size-14 text-cool-gray-30">Last Name</div>
        <div className="size-20 text-cool-gray-10">{get(dealer, 'dealerContactLastName')}</div>
      </Col>
    </Row>
    <Row className="mb-1">
      <Col xs={6}>
        <div className="size-14 text-cool-gray-30">Phone</div>
        <div className="size-20 text-cool-gray-10">{obscurePhone(get(dealer, 'dealerPhone', ''))}</div>
      </Col>
      <Col xs={6}>
        <div className="size-14 text-cool-gray-30">Dealer ZIP</div>
        <div className="size-20 text-cool-gray-10">{get(dealer, 'dealerZipCode')}</div>
      </Col>
    </Row>
    <Row className="mb-1_5">
      <Col>
        <div className="size-14 text-cool-gray-30">Preferred Email for Receiving Offers</div>
        <div className="size-20 text-cool-gray-10">{obscureEmail(get(dealer, 'dealerContactEmail', ''))}</div>
      </Col>
    </Row>
    {children}
  </div>
);

DeactivationFormView.propTypes = {
  dealer: PropTypes.shape({}),
  children: PropTypes.node,
};

DeactivationFormView.defaultProps = {
  dealer: {},
  children: null,
};
