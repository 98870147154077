export const EDMUNDS_ENROLLMENT_SOURCE = 'Edmunds';

export const STATUS_UNKNOWN = 'invalid';
export const STATUS_ELIGIBLE = 'eligible';
export const STATUS_INELIGIBLE = 'valid';
export const STATUS_ACTIVATED = 'enrolled';

export const DEALER_STATUS = {
  [STATUS_UNKNOWN]: 'franchise invalid',
  [STATUS_ELIGIBLE]: 'franchise eligible',
  [STATUS_INELIGIBLE]: 'franchise valid without inventory',
  [STATUS_ACTIVATED]: 'franchise already enrolled',
};
