import React from 'react';
import { get } from 'lodash';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { getModelState } from 'client/data/luckdragon/model';
import { bindToContent, CmsModel } from 'client/data/models/cms';
import { parseContent } from 'client/data/cms/content';
import { getMeta, getMetaRobots } from 'site-modules/shared/utils/seo-helper';
import { pageDefinition } from 'site-modules/shared/pages/page';
import { DealerCenterDecorator } from 'site-modules/microsite/dealers/decorators/dealer-center-decorator';
import {
  getDealerCenterPageContentPath,
  isDigitalCheckoutPage,
} from 'site-modules/microsite/dealers/utils/dealer-center-utils';
import { handle404Error } from 'site-modules/shared/utils/errors';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { PAGE_NAMES } from 'site-modules/shared/constants/microsite/dealers/dealers';
import { DEALER_CENTER_PAGE_CATEGORY } from 'site-modules/microsite/dealers/constants/page';
import { DigitalCheckoutModel, PATHS as DigitalCheckoutPaths } from 'client/data/models/digital-checkout';
import { getQuery } from 'client/utils/location';

import { DealerCenterUI } from './dealer-center';

export const connectToModelConfiguration = {
  content: bindToContent(({ pageCategory, pageName }) => getDealerCenterPageContentPath({ pageCategory, pageName })),
};

const DealerCenter = connectToModel(DealerCenterUI, connectToModelConfiguration);

export const getPageOptions = ({ pageCategory, pageName }) => ({
  name: PAGE_NAMES.getDealerCenterPageName(pageCategory),
  category: DEALER_CENTER_PAGE_CATEGORY,
  preload: (store, props) => {
    const preloader = new ModelPreloader(store);
    const articlePath = getDealerCenterPageContentPath({ pageCategory, pageName });

    preloader.resolve(`content["${articlePath}"]`, CmsModel);

    return preloader.load().then(modelState => {
      const query = getQuery(props.location);
      const rooftopId = get(query, 'rooftopId');

      if (isDigitalCheckoutPage(pageName, props?.location) && rooftopId) {
        preloader.resolve(DigitalCheckoutPaths.DEALERS({ rooftopId }), DigitalCheckoutModel);
      }

      const article = modelState.get(`content["${articlePath}"]`, CmsModel);

      if (!(article && article.id)) {
        throw handle404Error({ errorMessage: `No content was found for ${articlePath}` });
      }

      return preloader.load();
    });
  },
  seo: state => {
    const modelState = getModelState(state);
    const content = modelState.get(
      `content["${getDealerCenterPageContentPath({ pageCategory, pageName })}"]`,
      CmsModel
    );
    const parsedContent = parseContent(content);

    return {
      ...getMeta(parsedContent),
      ...getMetaRobots(parsedContent),
    };
  },
  decorator: DealerCenterDecorator,
  component: props => <DealerCenter pageName={pageName} pageCategory={pageCategory} location={props?.location} />,
});

export default params => pageDefinition(getPageOptions(params));
