import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { Container } from 'reactstrap';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { AnimatedCollapse } from 'client/site-modules/shared/utils/collapse-utils';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { FadeInSlideUpOnScroll } from 'site-modules/shared/components/animated/fade-in-slide-up';

import './dealer-center-faq.scss';

export function DealerCenterFaq({ entry }) {
  const faqEntries = entry.children();
  const [accordionStatus, setAccordionStatus] = useState(() =>
    faqEntries.reduce((acc, item) => {
      acc[item.id] = false;
      return acc;
    }, {})
  );

  const toggle = useCallback(e => {
    const key = e.target.name;
    setAccordionStatus(prevAccordionStatus => ({
      ...prevAccordionStatus,
      [key]: !prevAccordionStatus[key],
    }));
  }, []);

  if (isEmpty(faqEntries)) return null;

  return (
    <div className="dealer-center-faq my-2_5 px-0_75 px-md-0" data-tracking-parent="dealercenter-faq">
      <FadeInSlideUpOnScroll>
        <Container className="dealer-center-faq-container">
          <h2 className="header font-weight-bold text-cool-gray-10 p-0 mb-2_5">FAQ</h2>
          {faqEntries.map(item => {
            const key = item.id;
            const { question, answer } = item.getAllMetadata();
            const isOpen = accordionStatus[key];

            return (
              <div className="faq-item" key={key}>
                <button
                  onClick={toggle}
                  name={key}
                  className="faq-item-btn border-0 w-100 text-blue-50 p-0 d-flex justify-content-between mb-1_5"
                >
                  <span className="font-weight-bold size-24">{question}</span>
                  <i
                    className={classNames('align-self-center size-20', {
                      'icon-arrow-up4': !isOpen,
                      'icon-arrow-down4': isOpen,
                    })}
                  />
                </button>
                <AnimatedCollapse isOpen={isOpen}>
                  <ContentFragment classes="text-cool-gray-40 size-20 mb-1_5">{answer}</ContentFragment>
                </AnimatedCollapse>
              </div>
            );
          })}
        </Container>
      </FadeInSlideUpOnScroll>
    </div>
  );
}

DealerCenterFaq.propTypes = {
  entry: CmsEntities.Content,
};

DealerCenterFaq.defaultProps = {
  entry: DEFAULT_CONTENT,
};
