import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { DealerInfoModel, getDealerInfoNamePath } from 'client/data/models/dealer-info';
import { withMetrics } from 'client/data/api/api-metrics';

function getDealerInventory(response) {
  return response.json().then(responseJson => responseJson.response || null);
}

function getDealerInventoryById(id, context) {
  const dealerInventoryUrl = `/inventory/v5/rooftop/${id}/inventoriescount`;
  return withMetrics(EdmundsAPI, context)
    .fetch(dealerInventoryUrl)
    .then(getDealerInventory);
}

export const DealerInventoryModel = createModelSegment('dealerInventory', [
  {
    path: 'dealerInventory.id.{dealerId}',
    resolve(match, context) {
      return getDealerInventoryById(match.dealerId, context);
    },
  },
  {
    path: 'dealerInventory.name.{logicalName}',
    resolve(match, context) {
      return context.resolveValue(getDealerInfoNamePath(match.logicalName), DealerInfoModel).then(dealerInfo => {
        if (!dealerInfo) return null;
        const { id } = dealerInfo;
        return getDealerInventoryById(id, context);
      });
    },
  },
]);
