import { ALL_DIGITAL_CHECKOUT_PAGE_NAME } from 'site-modules/microsite/dealers/constants/dealer-center';
import { getQuery } from 'client/utils/location';

export function getDealerCenterPageContentPath({ pageCategory, pageName }) {
  return pageCategory ? `/dealer-center/${pageCategory}/${pageName}` : `/dealer-center/${pageName}`;
}
export function isDigitalCheckoutPageName(pageName) {
  return pageName === ALL_DIGITAL_CHECKOUT_PAGE_NAME;
}
export function isDigitalCheckoutPage(pageName, location) {
  const query = getQuery(location);
  return isDigitalCheckoutPageName(pageName) && 'digitalcheckout' in query;
}
