import { get } from 'lodash';
import { ClientConfig } from 'client/configuration';
import { EdmundsAPI } from 'client/data/api/api-client';
import { getLocationByZipCode } from 'site-modules/shared/utils/location';
import { getCaramelAPIPayload } from 'site-modules/microsite/dealers/utils/delaer-center-enroll-form-utils';
import { ENROLL_MODE } from 'site-modules/microsite/dealers/constants/digital-checkout';

export const enrollDealer = async ({ dealer, pageName, venomVersion, fields, mode }) => {
  const isEnrollMode = mode === ENROLL_MODE;
  const options = {
    method: isEnrollMode ? 'POST' : 'PUT',
    retries: 0,
    headers: {
      'content-type': 'application/json',
      'x-api-key': ClientConfig.get('carBuying').caramelApiKey,
    },
    timeout: ClientConfig.get('carBuying').apiDefaultTimeoutMs,
  };

  const dealerLocation = await getLocationByZipCode(get(dealer, 'dealerZipCode'), { pageName, venomVersion });
  const payload = getCaramelAPIPayload({ dealer, location: dealerLocation, fields, mode });
  const caramelAPIUrl = `/car-buying/v1/caramel/enrollment${isEnrollMode ? '' : `/${get(dealer, 'franchiseId')}`}`;
  await EdmundsAPI.fetchJson(caramelAPIUrl, {
    ...options,
    body: JSON.stringify(payload),
  });
};
