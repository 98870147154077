import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { CmsEntities } from 'client/data/models/cms';
import { renderEntries } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-entries-renderer';
import { getCtaText } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-helper';
import {
  FEES_AND_BONUSES_BACKGROUND_MOBILE,
  FEES_AND_BONUSES_BACKGROUND,
} from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-backgrounds';
import { DigitalCheckoutActivateButton } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activate-button/digital-checkout-activate-button';

import './digital-checkout-fees-and-bonuses.scss';

export const DigitalCheckoutFeesAndBonusesUI = ({ entry, isMobile, dealerStatus }) => {
  const metadata = entry.getAllMetadata();

  if (isEmpty(metadata)) return null;

  const { header, ctaText, activatedCtaText, invalidCtaText } = metadata;

  return (
    <div className="pos-r" id="payment-table" data-tracking-parent="caramel-fees-and-bonuses">
      <figure className="pos-a w-100 m-0">
        {isMobile ? FEES_AND_BONUSES_BACKGROUND_MOBILE : FEES_AND_BONUSES_BACKGROUND}
      </figure>
      <Container className="pt-3_5">
        <h2 className="px-0_5 px-md-0 mt-md-3 text-white mb-2 size-36 text-center">{header}</h2>
        <Row
          className={classnames('mt-1_75 d-flex fees-and-bonuses-wrapper px-1 px-md-0', {
            'justify-content-between': !isMobile,
          })}
        >
          {renderEntries({ entriesToRender: entry.children() })}
        </Row>
        <div className="d-flex">
          <DigitalCheckoutActivateButton
            cta={getCtaText({ dealerStatus, ctaText, activatedCtaText, invalidCtaText })}
            classNames="mt-2_5 mx-auto"
            icon="icon-arrow-up8"
          />
        </div>
      </Container>
    </div>
  );
};

DigitalCheckoutFeesAndBonusesUI.propTypes = {
  entry: CmsEntities.Content.isRequired,
  isMobile: PropTypes.bool,
  dealerStatus: PropTypes.string,
};

DigitalCheckoutFeesAndBonusesUI.defaultProps = {
  isMobile: false,
  dealerStatus: '',
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const DigitalCheckoutFeesAndBonuses = connect(mapStateToProps)(DigitalCheckoutFeesAndBonusesUI);
