import React from 'react';
import PropTypes from 'prop-types';
import { AnimatedOnScrollElement } from 'site-modules/shared/components/animated-on-scroll-element/animated-on-scroll-element';

import './grow.scss';

export function GrowOnScroll({ className, children }) {
  return (
    <AnimatedOnScrollElement className={className} animationClassNames={['grow']}>
      {children}
    </AnimatedOnScrollElement>
  );
}

GrowOnScroll.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

GrowOnScroll.defaultProps = {
  className: '',
  children: null,
};
