import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './infinite-animated-scroller.scss';

const MIN_LOOP_INSTANCES = 2;

export function InfiniteAnimatedScroller({ animationDuration, direction, children, isPlaying }) {
  const [looperInstances, setLooperInstances] = useState(MIN_LOOP_INSTANCES);
  const [isAnimated, setIsAnimated] = useState(true);
  const [isResettingAnimation, setIsResettingAnimation] = useState(false);

  const outerRef = useRef();
  const innerRef = useRef();

  useEffect(() => {
    if (isResettingAnimation && !isAnimated) {
      setIsAnimated(true);
      setIsResettingAnimation(false);
    }
  }, [isAnimated, isResettingAnimation]);

  function resetAnimation() {
    setIsAnimated(false);
    setIsResettingAnimation(true);
  }

  const setupInstances = useCallback(() => {
    const { width: instanceWidth } = innerRef.current.getBoundingClientRect();
    const { width: parentWidth } = outerRef.current.getBoundingClientRect();

    if (!instanceWidth) return;

    setLooperInstances(Math.ceil(parentWidth / instanceWidth) + 1);

    // Restart the animation when number of instances changes to avoid undesired visual effects
    resetAnimation();
  }, []);

  useEffect(() => setupInstances(), [setupInstances]);

  useEffect(() => {
    window.addEventListener('resize', setupInstances);

    return () => {
      window.removeEventListener('resize', setupInstances);
    };
  }, [setupInstances]);

  return (
    <div className="infinite-animated-scroller pos-r w-100" ref={outerRef}>
      <div className={classnames('inner-list d-flex justify-content-center', { animated: isAnimated })}>
        {[...Array(looperInstances)].map((element, ind) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={ind}
            className={classnames('list-instance', { paused: !isPlaying })}
            style={{
              animationDuration: `${animationDuration}s`,
              animationDirection: direction === 'right' ? 'reverse' : 'normal',
            }}
            aria-hidden={ind !== 0} // hide duplicated elements from screen readers
            ref={ind === 0 ? innerRef : null}
          >
            {children}
          </div>
        ))}
      </div>
    </div>
  );
}

InfiniteAnimatedScroller.propTypes = {
  animationDuration: PropTypes.number,
  direction: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
  isPlaying: PropTypes.bool,
};

InfiniteAnimatedScroller.defaultProps = {
  animationDuration: 30,
  direction: 'left',
  children: null,
  isPlaying: true,
};
