import React from 'react';
import { DigitalCheckoutPricingAndFee } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-pricing-and-fee/digital-checkout-pricing-and-fee';
import { DigitalCheckoutExplanations } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-explanations/digital-checkout-explanations';
import { DigitalCheckoutHero } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-hero/digital-checkout-hero';
import { DigitalCheckoutBenefits } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-benefits/digital-checkout-benefits';
import { DigitalCheckoutHowItWorks } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-how-it-works/digital-checkout-how-it-works';
import { DigitalCheckoutFeesAndBonuses } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-fees-and-bonuses/digital-checkout-fees-and-bonuses';
import { DigitalCheckoutActivationForm } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/digital-checkout-activation-form';

const entriesTypes = {
  heroDigitalCheckout: /hero-digital-checkout/i,
  benefits: /benefits/i,
  howItWorks: /how-it-works/i,
  activationForm: /activation-form/i,
  feesAndBonuses: /fees-and-bonuses/i,
  pricingAndFee: /pricing-and-fee/i,
  explanations: /explanations/i,
};

export const renderEntries = ({ entriesToRender, pageName, location, dealerStatus }) =>
  entriesToRender.reduce((entries, entry, index) => {
    const key = `${entry.id}-${index.toString()}`;
    switch (true) {
      case entriesTypes.heroDigitalCheckout.test(entry.id): {
        entries.push(<DigitalCheckoutHero key={key} entry={entry} dealerStatus={dealerStatus} />);
        break;
      }
      case entriesTypes.howItWorks.test(entry.id): {
        entries.push(<DigitalCheckoutHowItWorks key={key} entry={entry} />);
        break;
      }
      case entriesTypes.benefits.test(entry.id): {
        entries.push(<DigitalCheckoutBenefits key={key} entry={entry} dealerStatus={dealerStatus} />);
        break;
      }
      case entriesTypes.activationForm.test(entry.id): {
        entries.push(<DigitalCheckoutActivationForm key={key} entry={entry} pageName={pageName} location={location} />);
        break;
      }
      case entriesTypes.feesAndBonuses.test(entry.id): {
        entries.push(<DigitalCheckoutFeesAndBonuses key={key} entry={entry} dealerStatus={dealerStatus} />);
        break;
      }
      case entriesTypes.pricingAndFee.test(entry.id): {
        entries.push(<DigitalCheckoutPricingAndFee key={key} entry={entry} />);
        break;
      }
      case entriesTypes.explanations.test(entry.id): {
        entries.push(<DigitalCheckoutExplanations key={key} entry={entry} />);
        break;
      }
      default:
        break;
    }
    return entries;
  }, []);
